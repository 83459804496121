import type { InjectionKey, Ref } from "vue"
import { inject, provide } from "vue"
import type { MenuItem } from "primevue/menuitem"

export interface WcMenuContext {
  addItem: (item: MenuItem) => void
  removeItem: (item: MenuItem) => void
}

const MenuKey = Symbol() as InjectionKey<WcMenuContext>

export function provideMenuContext(menuItems: Ref<MenuItem[]>) {
  const context: WcMenuContext = {
    addItem: (item: MenuItem) => {
      menuItems.value = [...menuItems.value, item]
    },
    removeItem: (item: MenuItem) => {
      menuItems.value = menuItems.value.filter((i) => i !== item)
    },
  }

  provide(MenuKey, context)
  return context
}

export function useMenuContext() {
  const context = inject(MenuKey)

  if (!context) {
    throw new Error("WcMenuItem must be used within a WcMenu")
  }

  return context
}
