<template>
  <Calendar
    ref="calendarRef"
    v-model="model"
    option-label="label"
    :placeholder="insetLabel"
    :name="name"
    class="w-full"
    :class="inputClass"
    panel-class="w-[480px]"
    selection-mode="range"
    show-icon
    hide-on-range-selection
    icon-display="input"
    manual-input
    show-button-bar>
    <template v-if="insetLabel || $slots.presetPanel" #header>
      <div v-if="$slots.presetPanel" class="m-2 w-full">
        <slot name="presetPanel" :select-preset="handlePresetSelect" :is-preset-active="isPresetActive" />
      </div>
      <div v-if="insetLabel && $slots.presetPanel" class="my-3 w-full border-t border-neutral-20" />
      <h4 v-if="insetLabel" class="text-subheading-1 m-2 w-full">{{ insetLabel }}</h4>
    </template>
  </Calendar>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { isSameDay } from "date-fns"
import Calendar from "primevue/calendar"
import type { InputSize } from "@/components/input"
import { useInputClass } from "@/components/input"

type CalendarWithInternalButtonClick = Calendar & {
  // This is an internal primevue calendar component method to trigger the
  // button click event on the calendar and close the panel
  onButtonClick: () => void
}

const model = defineModel<Date[] | null>()
const props = defineProps<{
  insetLabel?: string
  name: string
  size?: InputSize
}>()

defineSlots<{
  presetPanel?: (props: { selectPreset: (dates: Date[]) => void; isPresetActive: (startDate: Date, endDate: Date) => boolean }) => any
}>()

const calendarRef = ref<CalendarWithInternalButtonClick | null>(null)
const inputClass = useInputClass(props)

const handlePresetSelect = (dates: Date[]) => {
  model.value = dates
  calendarRef.value?.onButtonClick()
}

const isPresetActive = (startDate: Date, endDate: Date) => {
  return model.value?.length === 2 && isSameDay(model.value[0], startDate) && isSameDay(model.value[1], endDate)
}
</script>

<style lang="scss">
// TODO: Design the calendar component. It's currently using default PrimeVue styles + tailwind

// Set styles on a few PrimeVue elements to make things coherent
.p-datepicker-month {
  margin-right: 7px;
}
.p-datepicker-buttonbar {
  @apply mx-4;
}
</style>
