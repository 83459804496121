<template>
  <div :id="chartId" class="bg-white dark:bg-blue-99"></div>
</template>

<script setup lang="ts">
// Libraries
import * as echarts from "echarts"
import {
  EChartsOption,
  EChartsType,
  DefaultLabelFormatterCallbackParams,
  TooltipComponentFormatterCallbackParams,
  TooltipComponentFormatterCallback,
  XAXisComponentOption,
  DataZoomComponentOption,
  SeriesOption,
} from "echarts"
import { onMounted, watch } from "vue"
import { sanitizeHtml } from "@/utils/sanitizeHtml"

// Models
import type { AssetLoadshapeRow } from "@/modules/asset/assetLoadshape.model"
import { LoadshapeInterval } from "@/modules/asset/assetLoadshape.model"

// Services
import { VizHelper } from "@/components/visualizations/VizHelper"
import { useMainStore } from "@/store"

// ECharts doesn't export this type, so defining here
type CategoryAxisBaseOption = {
  data: Record<string, any>
}

type Props = {
  data: Array<AssetLoadshapeRow>
  hasElectricityRows?: boolean
  height?: string
  interval?: LoadshapeInterval
  width?: string
}

const series = {
  Energy: { name: "Energy", unit: "kWh" },
  Carbon: { name: "Carbon", unit: "kg" },
  "Carbon Intensity": { name: "Carbon Intensity", unit: "kg/MWh" },
}

const props = withDefaults(defineProps<Props>(), {
  interval: LoadshapeInterval.hourly,
})
const emit = defineEmits(["onChange"])

const store = useMainStore()

const chartId = "energy-line-chart-" + new Date().getMilliseconds()

type SeriesDataValue = number | null

const constructSeriesList = (data: { carbon: SeriesDataValue[]; energy: SeriesDataValue[]; intensity: SeriesDataValue[] }): SeriesOption[] => {
  const { carbon, energy, intensity } = data
  const hasASingleCarbonValue = carbon.filter((value) => value !== null && value > 0).length === 1
  const hasASingleIntensityValue = intensity.filter((value) => value !== null && value > 0).length === 1

  const energySeries = {
    name: "Energy",
    type: "bar" as any,
    data: energy,
    itemStyle: {
      color: store.colors.energy,
    },
  }

  const carbonSeries = {
    name: "Carbon",
    type: "line" as any,
    step: "middle",
    data: carbon,
    // Enable the symbol and disable the line when there is only one data point
    symbol: hasASingleCarbonValue ? "circle" : "none",
    symbolSize: 10,
    itemStyle: {
      color: store.colors.carbon,
    },
    lineStyle: {
      color: store.colors.carbon,
      opacity: hasASingleCarbonValue ? 0 : 1,
    },
    yAxisIndex: 1,
  }

  const intensitySeries = {
    name: "Carbon Intensity",
    type: "line" as any,
    step: "middle",
    data: intensity,
    // Enable the symbol and disable the line when there is only one data point
    symbol: hasASingleIntensityValue ? "circle" : "none",
    symbolSize: 10,
    itemStyle: {
      color: store.colors.carbonIntensity,
    },
    lineStyle: {
      color: store.colors.carbonIntensity,
      opacity: hasASingleIntensityValue ? 0 : 1,
    },
    yAxisIndex: 2,
  }

  if (props.hasElectricityRows) {
    return [energySeries, carbonSeries, intensitySeries]
  } else {
    return [carbonSeries]
  }
}

const option: EChartsOption = {
  legend: {
    data: Object.keys(series),
    inactiveColor: "#777",
    textStyle: {
      color: store.colors.text,
    },
    right: 0,
    top: 0,
  },
  grid: {
    left: 50,
    top: 80,
    right: 60,
    bottom: 80,
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      animation: false,
      type: "line",
      lineStyle: {
        color: store.colors.text,
        width: 2,
        opacity: 1,
      },
    },
    formatter: ((params: DefaultLabelFormatterCallbackParams[]) => {
      const seriesParams = params.filter((param) => (param as any).axisType === "xAxis.category")

      if (seriesParams == null || seriesParams.length === 0) {
        return ""
      }

      const header = `<div class="mb-1">${seriesParams[0].name}</div>`
      const container = `
      <div class="grid grid-cols-[auto_auto_auto] gap-1">
        ${seriesParams
          .map((param) => {
            const value = typeof param.value === "number" ? param.value.toFixed(2) : ""
            const unit = value != "" ? series[param.seriesName as keyof typeof series].unit : ""
            return `
              <span>${param.marker} ${param.seriesName}</span>
              <span class="font-semibold text-right ml-1">${value}</span>
              <span class="text-left">${unit}</span>
            `
          })
          .join("")}
      </div>`

      return sanitizeHtml(header + container)
    }) as TooltipComponentFormatterCallback<TooltipComponentFormatterCallbackParams> | undefined | string,
  },
  xAxis: {
    type: "category",
    data: [],
    axisLine: { lineStyle: { color: store.colors.grid } },
    axisLabel: {
      color: store.colors.text,
      fontSize: 10,
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: [
    {
      type: "value",
      name: series["Energy"].unit,
      scale: false,
      axisLine: { lineStyle: { color: store.colors.text } },
      axisLabel: {
        color: store.colors.text,
        formatter: (value: number) => Math.round(value).toString(),
        fontSize: 10,
      },
      axisPointer: {
        show: true,
        label: {
          formatter: ({ value }: { value: any }) => (value != null ? `${Math.round(value)} ${series["Energy"].unit}` : value),
        },
      },
    },
    {
      type: "value",
      name: series["Carbon"].unit,
      scale: false,
      axisLine: { lineStyle: { color: store.colors.text } },
      axisLabel: {
        color: store.colors.text,
        formatter: (value: number) => Math.round(value).toString(),
        fontSize: 10,
      },
      axisPointer: {
        show: true,
        label: {
          formatter: ({ value }: { value: any }) => (value != null ? `${Math.round(value)} ${series["Carbon"].unit}` : value),
        },
      },
    },
    {
      type: "value",
      show: false,
    },
  ],
  dataZoom: {
    type: "slider",
    textStyle: {
      color: store.colors.text,
    },
    backgroundColor: store.colors.background,
    borderColor: store.colors.background,
    dataBackground: {
      lineStyle: {
        opacity: 1,
        color: store.colors.grid,
      },
    },
    fillerColor: "rgb(0,0,0,.03)",
    brushStyle: {
      color: store.colors.energy,
      borderColor: store.colors.energy,
      opacity: 0.2,
    },
    handleStyle: {
      color: store.colors.grid,
      borderColor: store.colors.grid,
      opacity: 0.7,
    },
    moveHandleStyle: {
      color: store.colors.grid,
      borderColor: store.colors.grid,
      opacity: 0.4,
    },
    emphasis: {
      handleStyle: {
        color: store.colors.grid,
        borderColor: store.colors.grid,
        opacity: 0.8,
      },
      moveHandleStyle: {
        color: store.colors.grid,
        borderColor: store.colors.grid,
        opacity: 0.5,
      },
    },
    selectedDataBackground: {
      lineStyle: {
        color: store.colors.grid,
        opacity: 1,
      },
      areaStyle: {
        color: store.colors.energy,
        opacity: 1,
      },
    },
    brushSelect: true,
  },
  series: constructSeriesList({ energy: [], carbon: [], intensity: [] }),
}

let chart: EChartsType

const renderChart = (rows: Array<AssetLoadshapeRow>) => {
  const dates = rows.map((h: AssetLoadshapeRow) => {
    if (props.interval === LoadshapeInterval.hourly) {
      return h.UTCHourLabel
    }
    if (props.interval === LoadshapeInterval.daily) {
      return h.DayLabel
    }
    if (props.interval === LoadshapeInterval.timeOfDay) {
      return h.TimeOfDayLabel
    }
    return h.MonthLabel
  })
  const _energy = rows.map((h: AssetLoadshapeRow) => h.NetElectricityKilowattHours)
  const _carbon = rows.map((h: AssetLoadshapeRow) => h.NetCarbonEmittedKilograms)
  const _intensity = rows.map((h: AssetLoadshapeRow) => h.CarbonIntensityKgPerMWh)
  const intervals = 7

  const nonNullEnergyValues = _energy.filter((value) => value !== null) as number[]
  const { ticks: energyTicks } = VizHelper.getAxisTicks(Math.min(...nonNullEnergyValues), Math.max(...nonNullEnergyValues), intervals)

  const nonNullCarbonValues = _carbon.filter((value) => value !== null) as number[]
  const { ticks: carbonTicks } = VizHelper.getAxisTicks(Math.min(...nonNullCarbonValues), Math.max(...nonNullCarbonValues), intervals)

  const energyMin = Math.min(...energyTicks)
  const energyMax = Math.max(...energyTicks)
  const carbonMax = Math.max(...carbonTicks)
  const carbonMin = Math.min(...carbonTicks)

  const yMax = energyMax > carbonMax ? energyMax : carbonMax
  const yMin = energyMin < carbonMin ? energyMin : carbonMin

  chart.setOption(
    {
      dataZoom: [
        {
          start: 0,
          end: 100,
        },
      ],
      legend: {
        data: props.hasElectricityRows ? ["Energy", "Carbon", "Carbon Intensity"] : ["Carbon"],
      },
      xAxis: {
        data: dates,
      },
      yAxis: [
        {
          type: "value",
          min: yMin,
          max: yMax,
          show: props.hasElectricityRows,
          axisPointer: {
            show: props.hasElectricityRows,
          },
        },
        {
          type: "value",
          min: yMin,
          max: yMax,
        },
      ],
      series: constructSeriesList({ energy: _energy, carbon: _carbon, intensity: _intensity }),
    },
    { replaceMerge: ["series"] }
  )
}

onMounted(() => {
  const chartDom = document.getElementById(chartId)
  if (!chartDom) {
    return
  }
  chart = echarts.init(chartDom, undefined, {
    width: props.width,
    height: props.height,
  })
  if (option) {
    chart.setOption(option)
  }
  chart.on("dataZoom", () => {
    const options = chart.getOption()
    const dataZooms = options.dataZoom as Array<DataZoomComponentOption>
    const xAxis = options.xAxis as Array<XAXisComponentOption>
    const { startValue, endValue } = dataZooms[0]
    const xAxisData = (xAxis[0] as CategoryAxisBaseOption).data
    const startDate = xAxisData[startValue as string]
    const endDate = xAxisData[endValue as string]
    emit("onChange", { startDate, endDate })
  })
  renderChart(props.data)
  new ResizeObserver(() => chart.resize()).observe(chartDom)
})

watch(
  () => props.data,
  (value: Array<AssetLoadshapeRow>) => {
    renderChart(value)
  }
)
</script>
