<template>
  <WcDropdown v-model="selectedGroup" name="assetGroup" :options="groupOptions" inset-label="Group" />
</template>

<script setup lang="ts">
import type { SelectOption } from "@/components/input"
import { WcDropdown } from "@/components/input"
import type { AssetGroup } from "@common/models/asset"
import { AssetGroupOrderBy, AssetGroupSortBy } from "@/modules/asset/asset.service"
import { useAssetService } from "@/services/service-container"

const assetService = useAssetService()

const selectedGroup = defineModel<SelectOption<AssetGroup | null> | null>()

// Load asset group pages recursively to get the full set
const loadAllAssetGroups = async (initialUrl?: string) => {
  const allGroups = [] as AssetGroup[]

  const loadPage = async (url?: string) => {
    const result = await assetService.listAssetGroups({
      per_page: 25,
      url,
      orderBy: AssetGroupOrderBy.asc,
      sortBy: AssetGroupSortBy.group_name,
    })

    allGroups.push(...result.data)

    if (result.pageInfo.nextPageUrl) {
      await loadPage(result.pageInfo.nextPageUrl)
    }
  }

  await loadPage(initialUrl)
  return allGroups
}

const ungroupedOption = { label: "Ungrouped", value: null }

// TODO: Replace this with a virtualized infinite scroll dropdown when available
const groups = await loadAllAssetGroups()
const groupOptions = [ungroupedOption, ...groups.map((group) => ({ label: group.name, value: group }))]
</script>
