<template>
  <div class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
    <template v-for="group in assetGroups" :key="group.id">
      <router-link
        v-if="!isSelectionMode"
        :to="{ name: 'wc-asset-groups-asset-group', params: { assetGroupId: group.id } }"
        class="flex flex-col rounded-lg border border-neutral-30 px-4 pb-4 pt-3">
        <AssetGroupCardContent :group="group" @edit="showEditAssetGroupModal" @delete="deleteAssetGroup" />
      </router-link>

      <div
        v-else
        class="hover:bg-neutral-100 flex cursor-pointer flex-col rounded-lg border border-neutral-30 px-4 pb-4 pt-3"
        :class="{ 'bg-neutral-100': modelValue?.id === group.id }"
        @click="selectGroup(group)">
        <div class="flex items-start">
          <div class="-mr-3 w-full">
            <AssetGroupCardContent :group="group" is-selection-mode @edit="showEditAssetGroupModal" @delete="deleteAssetGroup" />
          </div>
          <input
            type="radio"
            :name="'asset-group-' + group.id"
            :checked="modelValue?.id === group.id"
            class="mt-1.5 cursor-pointer"
            @click.stop="selectGroup(group)" />
        </div>
      </div>
    </template>
  </div>
  <EmptyState v-if="assetGroups.length === 0" title="No assets groups">
    <a class="text-hyperlink" @click="showCreateAssetGroupModal">Create one or more groups</a> to organize your assets and track metrics across
    segments of your portfolio.
  </EmptyState>
  <AddAssetGroupModal ref="addAssetGroupModal" @asset-group-added="handleAssetGroupAdded" />
  <EditAssetGroupModal ref="editAssetGroupModal" @asset-group-updated="handleAssetGroupUpdated" />
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useRouter } from "vue-router"
import { useToast } from "vue-toastification"
import EmptyState from "@/components/ui/EmptyState.vue"
import { useConfirm } from "@/components/confirm/ConfirmContext"
import type { AssetGroup } from "@common/models/asset"
import AddAssetGroupModal from "./AddAssetGroupModal.vue"
import EditAssetGroupModal from "./EditAssetGroupModal.vue"
import type { AssetGroupWithSummary } from "@/modules/asset/asset.service"
import { useAssetService } from "@/services/service-container"
import AssetGroupCardContent from "./AssetGroupCardContent.vue"

const router = useRouter()
const toast = useToast()
const assetService = useAssetService()
const { confirm } = useConfirm()

defineProps<{
  assetGroups: AssetGroupWithSummary[]
  isSelectionMode?: boolean
  modelValue?: AssetGroupWithSummary | null
}>()

const emit = defineEmits<{
  "row-delete-clicked": [rowId: number, event: MouseEvent]
  "asset-group-updated": [assetGroup: AssetGroup | null]
  "update:modelValue": [value: AssetGroupWithSummary | null]
}>()

const addAssetGroupModal = ref()
const editAssetGroupModal = ref()

const selectGroup = (group: AssetGroupWithSummary) => {
  emit("update:modelValue", group)
}

const showCreateAssetGroupModal = () => {
  addAssetGroupModal.value.openModal()
}

const showEditAssetGroupModal = (assetGroupId: number) => {
  editAssetGroupModal.value.openModal(assetGroupId)
}

const deleteAssetGroup = async (assetGroup: AssetGroup) => {
  if (
    assetGroup &&
    (await confirm({
      title: `Delete ${assetGroup.name}`,
      message: `Are you sure you want to delete this asset group? When a group is deleted, the assets are moved to the "Ungrouped Assets" list. This action cannot be undone.`,
      confirmText: "Delete Asset Group",
    }))
  ) {
    try {
      await assetService.deleteAssetGroup(assetGroup.id)
      emit("asset-group-updated", null)
      toast.success(`Asset group ${assetGroup.name} deleted`)
    } catch (error) {
      toast.error("There was a problem deleting the asset group. Please try again.")
      throw error
    }
  }
}

const handleAssetGroupUpdated = (updatedGroup: AssetGroup | null) => {
  emit("asset-group-updated", updatedGroup)
}

const handleAssetGroupAdded = (addedGroup: AssetGroupWithSummary) => {
  router.push({ name: "wc-asset-groups-asset-group", params: { assetGroupId: addedGroup.id } })
}
</script>
