import { Supplier } from "./supplier"
import { Listing } from "./listing"
import { Account, Coordinates } from "./models"
import { ASSET_KIND, AssetKind } from "./asset"

export enum OrderStatusType {
  preview = "preview",
  reserved = "reserved",
  purchased = "purchased",
  completed = "completed",
  paid = "paid",
  fulfilled = "fulfilled",
  cancelled = "cancelled",
  failed = "failed",
}

export enum PortfolioStatusType {
  setup = "setup",
  funding = "funding",
  deploying = "deploying",
  complete = "complete",
  cancelled = "cancelled",
}

export enum EacMeasurementParameter {
  EACs = "eacs",
  Electricity = "electricity",
  CarbonDioxide = "carbon_dioxide",
  GhgEmissions = "ghg_emissions",
  UnlabeledLong = "unlabeled_long",
  UnlabeledShort = "unlabeled_short",
}

export class ListingOrder {
  id!: number
  accountId!: number
  buyerName!: string
  quantity!: number
  createdTime!: string
  updatedTime!: string
  paidTime!: string
  status!: OrderStatusType
  pricePenniesUsd!: number
  stripePaymentId!: string
  listing: Listing

  constructor(listing: Listing = new Listing()) {
    this.listing = Object.assign(new Listing(), listing)
  }
}

export class OrderCreate {
  portfolioId!: number
  quantity!: number
}

export class OrderUpdate extends OrderCreate {
  id!: number
  status!: OrderStatusType
}

export class Order {
  id = 0
  account!: Account
  accountId = 0
  buyerName!: string
  portfolio: Portfolio
  quantity = 0
  adjustmentPenniesUsd = 0
  promoCode!: string
  createdTime!: string
  updatedTime!: string
  paidTime!: string
  status!: OrderStatusType
  pricePenniesUsd!: number // This is a calculated field but needs to be set.
  stripePaymentId?: string

  constructor(portfolio: Portfolio = new Portfolio()) {
    this.portfolio = Object.assign(new Portfolio(), portfolio)
  }

  public static CarbonSavings(order: Order): number {
    return (order.portfolio.estimatedCarbonIntensityTonnesCo2PerMwh ?? 1) * order.quantity
  }

  public static Price(order: Order): number {
    if (order.quantity === 0) {
      return 0
    }
    return (order.portfolio.pricePenniesUsdPerUnit * order.quantity) / 100
  }

  public static PriceFormatted(order: Order): string {
    return Order.Price(order).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
  }

  public static AdjustmentFormatted(order: Order): string {
    return (order.adjustmentPenniesUsd / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
  }

  public static Total(order: Order): number {
    if (order.quantity === 0) {
      return 0
    }
    const total = (order.portfolio.pricePenniesUsdPerUnit * order.quantity) / 100 - order.adjustmentPenniesUsd / 100
    return total > 0 ? total : 0
  }

  public static TotalFormatted(order: Order): string {
    return Order.Total(order).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
  }

  public static PercentageFunded(order: Order): number {
    const total = Math.round((order.portfolio.quantitySold / order.portfolio.quantityTotal) * 100)
    return total > 0 ? total : 0
  }

  public static IsSuccessful(order: Order): boolean {
    return order.status !== OrderStatusType.preview && order.status !== OrderStatusType.cancelled && order.status !== OrderStatusType.failed
  }
}

export interface AllocationTotal {
  eacsAllocated: number
  carbonValueG: number
  updatedTime: string
}

export interface PortfolioAllocation {
  account: { id: number; name: string }

  quantity?: number
  percent?: number

  allocation: AllocationTotal
}

export interface OrderSummary {
  portfolio: Portfolio
  accountName: string
  orderCount: number
  firstOrderTime: string | null
  quantityTotal: number | null
  pricePenniesUsd: number | null

  eacsAllocated: number | null
  carbonValueG: number | null
  updatedTime: string | null
}

export interface DeviceSummary {
  id: number
  supplier: Supplier
  coordinates: Coordinates
  location: string
  city: string
  state: string
  kind: string
  allocation: AllocationTotal | null
}

export interface OrderSummaryDetails {
  orderSummary: OrderSummary
  devices: DeviceSummary[]
}

export class PortfolioSupplier {
  quantity!: number
}
export class PortfolioSupplierRequest {
  quantity!: number
  supplierId!: number
}

export const formatProjectCategory = (category: string): string => {
  switch (category) {
    case "demand_response":
      return "Demand Response"
    case "electrification":
      return "Electrification"
    case "renewables":
      return "Renewables"
    default:
      return category || ""
  }
}

export const formatAssetKind = (kind: AssetKind | string): string => {
  return ASSET_KIND[kind as AssetKind] || kind || ""
}

export const formatScopes = (scopes: Array<number>): string => {
  if (scopes.length == 1) {
    return `Scope ${scopes[0]}`
  } else if (scopes.length == 2) {
    return `Scope ${scopes[0]} & ${scopes[1]}`
  } else {
    return `Scope ${scopes[0]}, ${scopes[1]} & ${scopes[2]}`
  }
}

export enum PortfolioAllocationSource {
  orders = "orders",
  stakes = "stakes",
}

export class DateRange {
  lower!: string
  upper!: string
}

export class Portfolio {
  displayId!: string
  buyerScopes!: Array<number>
  dateRange: DateRange = new DateRange()
  dateRangeDescription!: string
  transferDeadline!: string
  hoursDescription!: string
  id!: number
  imageUrl!: string
  locationDescription!: string
  longDescription!: string
  units!: string
  buyerAgreementTemplate!: string
  name!: string
  pricePenniesUsdPerUnit!: number
  slug!: string
  provider: string | null = null
  quantitySold!: number
  quantityTotal!: number
  status: PortfolioStatusType = PortfolioStatusType.setup
  shortDescription!: string
  suppliers = new Array<Supplier>()
  estimatedCarbonIntensityTonnesCo2PerMwh!: number
  allocationSource: PortfolioAllocationSource = PortfolioAllocationSource.stakes

  public static CarbonSavings(portfolio: Portfolio): number {
    return (portfolio.estimatedCarbonIntensityTonnesCo2PerMwh ?? 1) * portfolio.quantityTotal
  }
  public static EmissionsImpact(portfolio: Portfolio) {
    return `${portfolio.estimatedCarbonIntensityTonnesCo2PerMwh * 1000} gCO2/kWh`
  }

  public static Scope(portfolio: Portfolio): Array<number> {
    if (portfolio.buyerScopes?.length) {
      return portfolio.buyerScopes
    } else {
      return [2]
    }
  }

  public static ScopeFormatted(portfolio: Portfolio): string {
    const scopes = Portfolio.Scope(portfolio)
    return formatScopes(scopes)
  }

  public static Units(portfolio: Portfolio): string {
    return portfolio.units === "mwh_electricity" ? "MWh" : "tCO2e"
  }

  public static PriceFormatted(portfolio: Portfolio): string {
    return (portfolio.pricePenniesUsdPerUnit / 100).toLocaleString("en-US", { style: "currency", currency: "USD" })
  }

  public static Category(portfolio: Portfolio): string {
    if (!portfolio.buyerAgreementTemplate) return ""
    return formatProjectCategory(portfolio.buyerAgreementTemplate)
  }

  public static Image(portfolio: Portfolio): string {
    if (!portfolio.imageUrl || portfolio.imageUrl.includes("static.wattcarbon.com/account_logos/wattcarbon_logo.png")) {
      // Fallbacks based on type of projects
      switch (portfolio.buyerAgreementTemplate) {
        case "demand_response":
          return "https://static.wattcarbon.com/wattcarbon/marketplace/DR_transmission.jpg"
        case "electrification":
          return "https://static.wattcarbon.com/wattcarbon/marketplace/HP_home.jpg"
        case "renewables":
          return "https://static.wattcarbon.com/wattcarbon/marketplace/SOLAR_roof.jpg"
        default:
          return ""
      }
    } else {
      return portfolio.imageUrl
    }
  }
}

const units: { [key in EacMeasurementParameter]: string[] } = {
  eacs: ["EACs", "thousand EACs", "million EACs", "billion EACs"],
  electricity: ["Wh", "kWh", "MWh", "GWh"],
  carbon_dioxide: ["gCO2", "kgCO2", "tCO2", "ktCO2"],
  ghg_emissions: ["gCO2e", "kgCO2e", "tCO2e", "ktCO2e"],
  unlabeled_long: ["", "thousand", "million", "billion"],
  unlabeled_short: ["", "k", "m", "b"],
}

// also accepts a parameter of type number, representing the number of decimal places. defaults to 2
export const getFormattedEacQuantity = (
  quantity: number,
  parameter: EacMeasurementParameter,
  decimalPlaces = 2,
  signDisplay: "auto" | "always" | "exceptZero" | "never" = "auto"
) => {
  let formattedQuantity: string, unit: string
  if (Math.abs(quantity) >= 1_000_000_000) {
    formattedQuantity = (quantity / 1_000_000_000).toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
      signDisplay,
    })
    unit = units[parameter][3]
  } else if (Math.abs(quantity) >= 1_000_000) {
    formattedQuantity = (quantity / 1_000_000).toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
      signDisplay,
    })
    unit = units[parameter][2]
  } else if (Math.abs(quantity) >= 1_000) {
    formattedQuantity = (quantity / 1_000).toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
      signDisplay,
    })
    unit = units[parameter][1]
  } else {
    formattedQuantity = quantity.toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
      signDisplay,
    })
    unit = units[parameter][0]
  }
  return {
    quantity: formattedQuantity,
    unit,
    toString: () => `${formattedQuantity} ${unit}`,
  }
}

export const getFormattedEacQuantityForMillions = (
  quantity: number,
  parameter: EacMeasurementParameter,
  decimalPlaces = 2,
  signDisplay: "auto" | "always" | "exceptZero" | "never" = "auto"
) => {
  const formattedQuantity = (quantity / 1_000_000).toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    signDisplay,
  })
  const unit = units[parameter][2]
  return {
    quantity: formattedQuantity,
    unit,
    toString: () => `${formattedQuantity} ${unit}`,
  }
}

export class PortfolioStakeCreate {
  accountId!: number
  weight!: number
}

export class PortfolioStake extends PortfolioStakeCreate {
  portfolioId!: number
}
