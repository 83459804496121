<script setup lang="ts">
import { onMounted, onUnmounted } from "vue"
import type { MenuItem } from "primevue/menuitem"
import type { ButtonColorLightMode } from "@/components/button/WcButton"
import { useMenuContext } from "./useMenuContext"

const props = withDefaults(
  defineProps<{
    color?: ButtonColorLightMode
    disabled?: boolean
    icon?: string
    text: string
    onClick?: () => void
  }>(),
  {
    color: "green",
    disabled: false,
  }
)

const menu = useMenuContext()

const item: MenuItem = {
  class: `wc-menu-item--${props.color}`,
  color: props.color,
  command: props.onClick,
  disabled: props.disabled,
  icon: props.icon,
  label: props.text,
}

onMounted(() => menu?.addItem(item))
onUnmounted(() => menu?.removeItem(item))
</script>
