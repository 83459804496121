<template>
  <Dialog ref="dialog" class="p-dialog-maximized manage-asset-group-assets-modal" modal :visible="isOpen" :closable="false" :draggable="false">
    <template #header>
      <div class="flex w-full flex-wrap items-center gap-3 text-white sm:justify-between">
        <div class="lg:basis-[78px]">
          <WcButtonIcon class="!top-0 size-7 cursor-pointer" icon="wc-carbon:close" color="green" variant="tertiary" @click="closeModal" />
        </div>
        <h1 class="text-body-1">Add assets to group</h1>
        <WcButton text="Close" size="medium" dark-mode @click="closeModal" />
      </div>
    </template>
    <div v-if="assetGroup">
      <h2 class="text-subheading-large-bold">{{ assetGroup.name }}</h2>

      <div class="mt-9 w-full border-t border-divider-extralight pt-9">
        <section class="flex flex-wrap gap-2 py-2">
          <WcDropdown
            v-model="filterAssetStatus"
            class="basis-40"
            name="filterAssetStatus"
            :options="statusOptions"
            inset-label="Status"
            size="small" />
          <AssetGroupDropdown v-model="filterAssetGroup" class="shrink-0 basis-40" size="small" />
          <WcCalendarRange
            v-model="filterAssetCreatedDateRange"
            class="shrink-0 basis-40"
            :class="{ 'basis-56': (filterAssetCreatedDateRange || [])[1] != null }"
            name="filterAssetCreatedDateRange"
            inset-label="Date created"
            size="small" />
          <WcInputText
            v-model="filterAssetLocationKeyword"
            icon="wc-carbon:search"
            class="shrink-0 basis-48"
            name="filterAssetLocationKeyword"
            inset-label="Filter by location"
            size="small" />
          <WcCTA
            class="shrink-0 basis-52 pl-1"
            icon-position="right"
            icon="wc-carbon:close-outline"
            text="Clear Filters"
            size="small"
            @click="handleClearFilters" />
        </section>

        <div class="mt-4 max-h-[calc(100vh-28rem)] overflow-x-scroll pb-3">
          <AssetsTable
            :assets="availableAssets"
            allow-multiple-selection
            select-on-row-click
            :selected-row-ids="selectedAvailableAssetIds"
            show-eacs
            show-group
            show-kind
            table-class="w-full"
            @rows-selected="handleAvailableRowsSelected" />
        </div>
        <PaginationButtons class="mt-3" :page-info="availableAssetsPageInfo" :is-disabled="isLoading" @load-items="loadAvailableAssets" />
        <EmptyState v-if="!isLoading && !hasError && availableAssets.length === 0" title="No assets available to add">
          <p v-if="assetGroup.deviceIds.length === 0">Upload one or more assets to add them to a group.</p>
          <p v-if="assetGroup.deviceIds.length > 0">All assets matching your filters have been added to this group</p>
        </EmptyState>
        <InlineLoading v-if="isLoading && availableAssets.length === 0" />
        <InlineError v-if="hasError" error-message="There was a problem loading assets. Please try again." />
        <div
          v-if="selectedAvailableAssetIds.length > 0"
          class="mt-8 flex h-16 w-full flex-wrap items-center justify-between gap-2 bg-darktone-lightbackground px-5 py-3">
          <div class="text-body-2 shrink-0">
            <div class="flex items-baseline gap-2">
              <span class="text-subheading-large-bold">{{ selectedAvailableAssetIds.length }}</span>
              <span class="text-subheading-1">assets selected</span>
            </div>
          </div>
          <div class="flex items-center gap-2">
            <WcButton
              text="Cancel"
              size="medium"
              variant="secondary"
              :is-disabled="selectedAvailableAssetIds.length === 0"
              @click="handleAvailableClearSelection" />
            <WcButton text="Add Assets" size="medium" :is-disabled="selectedAvailableAssetIds.length === 0" @click="addSelectedAssets" />
          </div>
        </div>
      </div>
    </div>
    <InlineLoading v-if="assetGroup == null && isLoading" />
    <InlineError v-if="assetGroup == null && hasError" error-message="There was a problem loading the asset group. Please try again." />
  </Dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue"
import { useToast } from "vue-toastification"
import { format } from "date-fns"
import Dialog from "primevue/dialog"
import type { AssetGroup, AssetStatus } from "@common/models/asset"
import { ASSET_STATUS } from "@common/models/asset"
import { WcButton, WcCTA } from "@/components/button"
import WcButtonIcon from "@/components/button/WcButtonIcon.vue"
import type { SelectOption } from "@/components/input"
import { WcCalendarRange, WcDropdown, WcInputText } from "@/components/input"
import EmptyState from "@/components/ui/EmptyState.vue"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import type { AssetWithSummary } from "@/modules/asset/asset.service"
import type { PageInfo } from "@/services/base-fetcher"
import { useAssetService } from "@/services/service-container"
import { debounce } from "@/utils/debounce"
import AssetGroupDropdown from "./AssetGroupDropdown.vue"
import AssetsTable from "./AssetsTable.vue"

const statusOptions = Object.entries(ASSET_STATUS).map(([status, { summary }]) => ({ label: summary, value: status }))

const assetService = useAssetService()
const toast = useToast()

const props = defineProps<{ assetGroupId: number }>()

const emit = defineEmits(["asset-group-assets-updated"])

const isOpen = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const hasError = ref<boolean>(false)
const dialog = ref()
const assetGroup = ref<AssetGroup | null>(null)
const availableAssets = ref<AssetWithSummary[]>([])
const availableAssetsPageInfo = ref<PageInfo>()

const filterAssetCreatedDateRange = ref<Date[] | null | undefined>(null)
const filterAssetGroup = ref<SelectOption<AssetGroup | null> | null>(null)
const filterAssetLastUpdateDateRange = ref<Date[] | null | undefined>(null)
const filterAssetStatus = ref<SelectOption<AssetStatus> | null>(null)
const filterAssetEacProductionDateRange = ref<Date[] | null | undefined>(null)
const filterAssetLocationKeyword = ref<string | null | undefined>(null)

const selectedAvailableAssetIds = ref<number[]>([])

const handleUpdateIsOpen = (newValue: boolean) => {
  isOpen.value = newValue
  if (!newValue) {
    resetModal()
  }
}

const resetModal = () => {
  assetGroup.value = null
  availableAssets.value = []
  selectedAvailableAssetIds.value = []
}

const loadAssetGroup = async () => {
  try {
    assetGroup.value = await assetService.getAssetGroup(props.assetGroupId)
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading this asset group", error)
  }
  isLoading.value = false
}

const loadAvailableAssets = async (url?: string) => {
  isLoading.value = true
  try {
    const createdDateStart = (filterAssetCreatedDateRange.value ?? [])[0]
    const createdDateEnd = (filterAssetCreatedDateRange.value ?? [])[1]

    const lastUpdateStart = (filterAssetLastUpdateDateRange.value ?? [])[0]
    const lastUpdateEnd = (filterAssetLastUpdateDateRange.value ?? [])[1]

    const eacProductionStart = (filterAssetEacProductionDateRange.value ?? [])[0]
    const eacProductionEnd = (filterAssetEacProductionDateRange.value ?? [])[1]

    const groupId = filterAssetGroup.value?.value?.id ?? undefined
    const ungrouped = filterAssetGroup.value?.value === null ? true : undefined

    const filters = {
      summaryDatetimeStart: "1970-01-01",
      summaryDatetimeEnd: "9999-01-01",
      ...(filterAssetStatus.value && { status: filterAssetStatus.value.value }),
      ...(filterAssetLocationKeyword.value && { location: filterAssetLocationKeyword.value }),
      ...(createdDateStart && { createdDateStart: format(createdDateStart, "yyyy-MM-dd") }),
      ...(createdDateEnd && { createdDateEnd: format(createdDateEnd, "yyyy-MM-dd") }),
      ...(lastUpdateStart && { lastUpdateStart: format(lastUpdateStart, "yyyy-MM-dd") }),
      ...(lastUpdateEnd && { lastUpdateEnd: format(lastUpdateEnd, "yyyy-MM-dd") }),
      ...(eacProductionStart && { eacProductionStart: format(eacProductionStart, "yyyy-MM-dd") }),
      ...(eacProductionEnd && { eacProductionEnd: format(eacProductionEnd, "yyyy-MM-dd") }),
      ...(groupId !== undefined && { groupId }),
      ...(ungrouped !== undefined && { ungrouped }),
    }
    const result = await assetService.listAssets({ url, excludedGroupId: props.assetGroupId, ...filters })
    availableAssets.value = result.data
    availableAssetsPageInfo.value = result.pageInfo
    selectedAvailableAssetIds.value = []
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading available assets", error)
  }
  isLoading.value = false
}

const loadData = () => {
  loadAssetGroup()
  loadAvailableAssets()
}

watch(
  [props.assetGroupId, isOpen],
  () => {
    if (props.assetGroupId && isOpen.value) {
      loadData()
    }
  },
  { immediate: true }
)

watch(
  [
    filterAssetCreatedDateRange,
    filterAssetGroup,
    filterAssetLastUpdateDateRange,
    filterAssetStatus,
    filterAssetEacProductionDateRange,
    filterAssetLocationKeyword,
  ],
  debounce(() => {
    loadAvailableAssets()
  })
)

const addSelectedAssets = async () => {
  if (!props.assetGroupId || selectedAvailableAssetIds.value.length === 0) {
    return
  }
  const hasSelectedAssetsInExistingGroups = availableAssets.value.some(
    (asset) => asset.group && selectedAvailableAssetIds.value.indexOf(asset.id) > -1
  )
  if (
    !hasSelectedAssetsInExistingGroups ||
    window.confirm(
      "One or more of the selected assets are already in a group and this will move them to the new group. Are you sure you want to continue?"
    )
  ) {
    try {
      await assetService.addAssetsToAssetGroup(props.assetGroupId, selectedAvailableAssetIds.value)
      emit("asset-group-assets-updated")
      toast.success(`${selectedAvailableAssetIds.value.length} ${selectedAvailableAssetIds.value.length === 1 ? "asset" : "assets"} added to group`)
      selectedAvailableAssetIds.value = []
      loadData()
    } catch (error) {
      console.error(error)
      toast.error("There was a problem adding assets to the group. Please try again.")
    }
  }
}

const handleClearFilters = () => {
  filterAssetCreatedDateRange.value = null
  filterAssetLastUpdateDateRange.value = null
  filterAssetStatus.value = null
  filterAssetEacProductionDateRange.value = null
  filterAssetLocationKeyword.value = null
}

const handleAvailableRowsSelected = (selectedRowIDs: number[]) => {
  selectedAvailableAssetIds.value = selectedRowIDs
}

const handleAvailableClearSelection = () => {
  selectedAvailableAssetIds.value = []
}

const openModal = () => {
  handleUpdateIsOpen(true)
}
const closeModal = () => handleUpdateIsOpen(false)
defineExpose({ openModal, closeModal })
</script>

<style lang="scss">
.manage-asset-group-assets-modal {
  .p-dialog-header {
    @apply px-4 md:px-[60px] py-3 bg-black;
  }
  .p-dialog-content {
    @apply px-4 md:px-[60px] py-12;
  }
  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    border-radius: 0;
  }
  .p-dialog-header-icons {
    display: none;
  }
}
</style>
