<template>
  <AssetEnergyOverTimeContainer v-show="!hasError" :is-loading="isLoading" :rows="rows" :summary="summary" @load-rows="handleLoadRows" />
  <InlineError v-if="hasError" error-message="There was a problem loading the loadshape for this asset. Please try again." />
</template>

<script setup lang="ts">
import { ref } from "vue"
import type { Asset } from "@common/models/asset"
import InlineError from "@/components/ui/InlineError.vue"
import type { AssetSummary, LoadshapeRequestOptions } from "@/modules/asset/asset.service"
import type { APIAssetLoadshapeRow } from "@/modules/asset/assetLoadshape.model"
import { useAssetService } from "@/services/service-container"
import AssetEnergyOverTimeContainer from "./AssetEnergyOverTimeContainer.vue"

const assetService = useAssetService()

const props = defineProps<{ asset: Asset; summary?: AssetSummary }>()

const rows = ref<APIAssetLoadshapeRow[]>([])
const isLoading = ref<boolean>(true)
const hasError = ref<boolean>(false)

const handleLoadRows = async (options: LoadshapeRequestOptions) => {
  isLoading.value = true
  try {
    const loadshape = await assetService.getAssetLoadshape(props.asset.id, options)
    rows.value = loadshape.rows
    hasError.value = false
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading this asset loadshape", error)
  }
  isLoading.value = false
}
</script>
