<template>
  <Dialog
    modal
    :visible="isOpen"
    close-on-escape
    closable
    dismissable-mask
    :class="{
      'modal-40': size === '40' || !size,
      'modal-60': size === '60',
      'modal-80': size === '80',
      disrupt: type === 'disrupt',
    }"
    :draggable="false"
    :style="{ width }"
    @update:visible="$emit('update:isOpen', $event)">
    <template #header>
      <Icon v-if="type === 'disrupt' && icon" :icon="icon" class="size-8" />
      <div class="text-subheading-1 text-black">{{ header }}</div>
    </template>
    <slot name="panel"></slot>
    <template v-if="$slots.footer" #footer>
      <div class="footer-buttons ph-no-capture flex w-full flex-wrap justify-between sm:justify-end">
        <slot name="footer"></slot>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue"
import { computed } from "vue"
import Dialog from "primevue/dialog"

defineEmits(["update:isOpen"])

defineSlots<{
  footer?: () => any
  panel?: () => any
}>()

type Props = {
  header: string
  icon?: string
  isOpen: boolean
  size?: "40" | "60" | "80"
}

type NonDisruptProps = Props & {
  type?: never
  icon?: never
}

type DisruptProps = Props & {
  type: "disrupt"
  icon: string
}

const props = withDefaults(defineProps<NonDisruptProps | DisruptProps>(), {
  size: "40",
})

const width = computed(() => {
  switch (props.size) {
    case "80":
      return "1152px" // 80% of the 1440px container
    case "60":
      return "864px" // 60% of the 1440px container
    case "40":
    default:
      return "576px" // 40% of the 1440px container
  }
})
</script>

<style lang="scss">
.p-dialog {
  border-radius: 0.5rem;
}
.p-dialog-header {
  border-radius: 0.5rem 0.5rem 0 0;
  align-items: flex-start;
  gap: 1rem;
}
.p-dialog-footer {
  border-radius: 0 0 0.5rem 0.5rem;
}
.p-dialog .p-dialog-content {
  @apply py-6;
}
.p-dialog {
  .p-dialog-content {
    @apply py-6;
  }
  .p-dialog-footer {
    @apply pt-2 pb-6;
  }
}
.p-dialog.modal-40 {
  .p-dialog-content,
  .p-dialog-header,
  .p-dialog-footer {
    @apply px-6;
  }

  .p-dialog-header {
    @apply py-4;
  }

  .p-dialog-footer {
    .footer-buttons {
      @apply justify-end gap-3;
    }
  }

  &.disrupt {
    .p-dialog-header-icons {
      display: none;
    }
    .p-dialog-header {
      padding-top: 3rem;
      padding-bottom: 0.25rem;
      border-bottom: none;
      flex-direction: column;
      gap: 1.5rem;
    }

    .p-dialog-content {
      padding-top: 0;
    }

    .p-dialog-header,
    .p-dialog-content,
    .p-dialog-footer {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
.p-dialog.modal-60 {
  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    @apply px-8;
  }

  .p-dialog-header {
    @apply py-6;
  }

  .p-dialog-footer {
    .footer-buttons {
      @apply gap-3;
    }
  }
}
.p-dialog.modal-80 {
  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    @apply px-8;
  }

  .p-dialog-header {
    @apply py-6;
  }

  .p-dialog-footer {
    .footer-buttons {
      @apply gap-4;
    }
  }
}
.p-dialog-header {
  border-bottom: 1px solid theme("colors.divider.light");
  @apply pt-[36px];
}
.p-dialog-footer {
  @apply pb-[36px];
}
.p-dialog-header-icon {
  height: 1.5rem;
  width: 1.5rem;
}
.p-dialog-header-icon > svg {
  color: theme("colors.black");
  height: 1.125rem;
  width: 1.125rem;
}
</style>
