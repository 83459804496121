<template>
  <div class="absolute right-16 top-6">
    <WcCTA
      v-if="site.id"
      text="Delete Site"
      icon="wc-carbon:delete"
      color="alert"
      size="medium"
      weight="heavy"
      data-cy="button-site-delete"
      @click="deleteSite()" />
  </div>
  <section>
    <div class="mt-2 flex flex-col justify-between">
      <div class="flex gap-2">
        <label class="w-1/3">
          Name
          <input v-model="site.name" type="text" class="mb-2 w-full" maxlength="128" data-cy="input-site-name" @change="debouncedSave()" />
        </label>
        <label class="w-2/3">
          Address
          <WcAutocompleteAddress :address="addressAuto" data-cy="input-site-address" @set-address="setAddress"></WcAutocompleteAddress>
        </label>
      </div>
    </div>
  </section>
  <section class="mt-4 flex h-96">
    <div class="h-full w-1/4 overflow-auto rounded-l-md border border-sagetone">
      <div class="flex items-center justify-between border-b py-2 pl-4">
        <div class="font-bold">Assets</div>
      </div>
      <div v-if="site.assets.length === 0" class="alert neutral w-full text-center">You have no Assets</div>
      <ul class="selectable divide-y divide-sagetone" data-cy="edit-asset-list">
        <li
          v-if="providers.length > 0 && site.id !== 0"
          data-cy="button-new-asset"
          class="p-2"
          :class="{ active: asset && asset.id === 0 }"
          @click="editAsset()">
          <div class="flex items-center text-blue-40">
            <Icon class="size-8" icon="wc-carbon:add" />
            <div class="pl-4">
              <div class="text-sm">New Asset</div>
            </div>
          </div>
        </li>
        <li
          v-for="a in site.assets"
          :key="a.id"
          class="flex justify-between p-2"
          :class="{ active: asset && a.id === asset.id }"
          @click="editAsset(a)">
          <div class="flex items-center">
            <div class="bg-gray-100 relative size-12 overflow-hidden rounded-full">
              <Icon class="absolute left-2 top-2 size-8" :icon="getAssetIcon(a.type)" />
            </div>
            <div class="pl-4">
              <div class="text-sm">{{ a.name }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="w-3/4 overflow-y-auto rounded-r-md border-y border-r border-sagetone">
      <div v-if="!asset" class="flex h-full items-center justify-center">
        <div class="flex size-3/4 items-center justify-center bg-neutral-30">Select an asset</div>
      </div>
      <WcSiteAssetEdit v-if="asset && providers.length > 0 && site.id !== 0" v-model:asset="asset" :providers="providers" @save:asset="onAssetSave" />
      <div v-else class="p-2">Please enter a site name and address above to begin creating assets</div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, PropType } from "vue"
import { Site } from "@common/models/site"
import { useSitesStore } from "@/store/sites"
import { SiteAsset } from "@common/models/siteAsset"
import { Icon } from "@iconify/vue"
import { Provider } from "@common/models/models"
import { debounce } from "lodash"
import { WcCTA } from "@/components/button"
import WcAutocompleteAddress from "@/components/WcAutocompleteAddress.vue"
import WcSiteAssetEdit from "./WcSiteAssetEdit.vue"
import { useSiteService } from "@/services/service-container"
import { gModelDict } from "@common/global"

const sitesStore = useSitesStore()
const siteService = useSiteService()

const props = defineProps({
  site: { type: Object as PropType<Site>, required: true },
})
const emit = defineEmits(["dismissed"])
const site = ref<Site>(props.site)
const asset = ref<SiteAsset>()
const providers = ref<Array<Provider>>([])
const formatAddress = (_site: Site) => {
  if (_site.type == "summary") {
    return ""
  }
  return `${_site.address.street} ${_site.address.city} ${_site.address.state} ${_site.address.postalCode}`
}

const getAssetIcon = (type: string) => {
  if (Object.hasOwn(gModelDict, type)) {
    return gModelDict[type].icon
  }
}
const addressAuto = ref(formatAddress(props.site))

const isValidSite = computed(() => {
  if (!(site.value.name.length > 0)) {
    return false
  }

  return site.value.address.postalCode ? true : false
})

const updateProviders = async () => {
  if (site.value.location.latitude && site.value.location.longitude) {
    providers.value = await siteService.getProviders(site.value.location.latitude, site.value.location.longitude)
  } else {
    providers.value = []
  }
}

const setAddress = async (address: any) => {
  site.value.address.street = address.street
  site.value.address.city = address.city
  site.value.address.postalCode = address.postalCode
  site.value.address.state = address.state
  site.value.address.country = "US"
  await save()
  await updateProviders()
}

let isSaving = false
const save = async () => {
  if (!isValidSite.value) {
    return
  }

  if (isSaving) {
    return
  }

  try {
    isSaving = true
    site.value = await sitesStore.updateSite(site.value)
    if (asset.value && asset.value.siteId === 0) {
      asset.value.siteId = site.value.id
    }
  } finally {
    isSaving = false
  }
}

const closeDialog = () => {
  emit("dismissed")
}

const debouncedSave = debounce(save, 250)

const deleteSite = async () => {
  const assetCount = site.value.assets.length
  let confirmed
  if (assetCount > 1) {
    confirmed = confirm(`Permanently delete "${site.value.name}" and its ${assetCount} assets?`)
  } else if (assetCount == 1) {
    confirmed = confirm(`Permanently delete "${site.value.name}" and its asset?`)
  } else {
    confirmed = true
  }

  if (confirmed) {
    try {
      await sitesStore.deleteSite(site.value.id)
    } catch {
      return
    }
    closeDialog()
  }
}

const editAsset = (a: SiteAsset = SiteAsset.GetEmpty(site.value.id)) => {
  asset.value = a
}

const init = async (_site: Site) => {
  if (!_site?.id) {
    addressAuto.value = ""
    return
  }
  _site = await sitesStore.fetchSite(_site.id)
  site.value = _site
  asset.value = undefined
  addressAuto.value = formatAddress(site.value)
}

const onAssetSave = async () => {
  await init(site.value)
}

onMounted(async () => {
  await updateProviders()
  await init(site.value)
})
</script>
