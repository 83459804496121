import ApiFetcher from "@/services/api-fetcher"
import { EacRetirementCreate, AccountBalanceSummary, AccountHourlyBalanceSummary, AccountSupplier } from "./registry.model"
import { format } from "date-fns"

export interface DateRange {
  startDate: Date
  endDate: Date
}

export interface DateTimeRange extends DateRange {
  startHour: number
  endHour: number
}

export interface GetBalancesOptions {
  dateTimeRange?: DateTimeRange
  url?: string
  supplierIds?: number[]
}

export interface PublicWeatsSummary {
  avgPricePenniesUsdPerGco2: number
  totalEacs: number
  totalAssetsRegistered: number
}

export default class RegistryService {
  fetcher: ApiFetcher

  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  downloadEacsForPortfolio = async (portfolioId: number) => {
    await this.fetcher.httpDownload(
      "/registry/balances",
      {
        portfolioId: portfolioId,
        responseFormat: "csv",
      },
      "EACs.csv"
    )
  }

  getBalances = async (options: GetBalancesOptions) => {
    let response
    if (options.dateTimeRange) {
      const { startDate, endDate, startHour, endHour } = options.dateTimeRange
      response = await this.fetcher.httpGetPaginated<string>("/registry/balances", {
        responseFormat: "csv",
        startDate: format(startDate, "yyyy-MM-dd"),
        endDate: format(endDate, "yyyy-MM-dd"),
        startHour,
        endHour,
        ...(options.supplierIds && { supplierId: options.supplierIds }),
      })
    } else if (options.url) {
      // strip the domain portion off the url as the fetcher will add it back
      const pathIndex = options.url.indexOf("/registry/balances")
      const url = options.url.slice(pathIndex)
      response = await this.fetcher.httpGetPaginated<string>(url, {})
    } else {
      throw Error("one of dateTimeRange or url required")
    }
    return response
  }

  getBalanceDateRange = async () => {
    return await this.fetcher.httpGet<{ lower: string; upper: string } | "">("/registry/balances/range", {})
  }

  getAllTimeBalanceSummary = async () => {
    return await this.fetcher.httpGet<AccountBalanceSummary>("/registry/balances/summary/all-time", {})
  }

  getAccountSuppliers = async () => {
    return await this.fetcher.httpGet<AccountSupplier[]>("/registry/suppliers", {})
  }

  getHourlyBalanceSummary = async (options: { startDate: Date; endDate: Date; accountIds?: number[] | null; supplierIds?: number[] | null }) => {
    const { startDate, endDate, accountIds, supplierIds } = options
    return await this.fetcher.httpGet<AccountHourlyBalanceSummary>("/registry/balances/summary/hourly", {
      responseFormat: "csv",
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(endDate, "yyyy-MM-dd"),
      ...(accountIds && { accountId: accountIds }),
      ...(supplierIds && { supplierId: supplierIds }),
    })
  }

  retireEacs = async (eacRetirements: EacRetirementCreate) => {
    return await this.fetcher.httpPut("/certificates/retire", eacRetirements)
  }

  getWeatsSummaryPublic = async () => {
    return await this.fetcher.httpGet<PublicWeatsSummary>("/meta/summary", {})
  }
}
