<template>
  <div class="mt-6 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
    <router-link
      v-for="summary in orderSummaries"
      :key="summary.portfolio.id"
      :to="{ name: 'wc-portfolio-tracker', params: { portfolioId: summary.portfolio.id } }"
      class="flex flex-col rounded-lg border border-neutral-30 px-4 pb-4 pt-3">
      <h3 class="text-subheading-1 mb-5 min-w-0 break-words">{{ summary.portfolio.name }}</h3>
      <div class="flex grow flex-col">
        <div class="grid grid-cols-2 gap-4">
          <div>
            <div class="text-subheading-large-bold">
              {{ getFormattedEacQuantity(summary.eacsAllocated ?? 0, EacMeasurementParameter.UnlabeledShort) }}
            </div>
            <div class="text-body-3">EACs allocated</div>
          </div>
          <div v-if="summary.quantityTotal !== null">
            <div class="text-subheading-large-bold">
              {{ getFormattedEacQuantity(summary.quantityTotal * 1_000_000, EacMeasurementParameter.UnlabeledShort) }}
            </div>
            <div class="text-body-3">EACs ordered</div>
          </div>
        </div>
        <WcProgressBarChart
          v-if="hasQuantityOrdered(summary)"
          class="mt-5"
          :percentage="computePercentAllocated(summary)"
          label="percentage fulfilled" />
      </div>
      <div v-if="summary.updatedTime !== null" class="text-caption mt-6 text-hint">Last updated: {{ formatDate(summary.updatedTime) }}</div>
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { getFormattedEacQuantity, EacMeasurementParameter } from "@common/models/order"
import { OrderSummary } from "@common/models/order"
import WcProgressBarChart from "@/components/visualizations/WcProgressBarChart.vue"

const formatter = new Intl.DateTimeFormat("en", { dateStyle: "short" })
const formatDate = (date: string) => formatter.format(new Date(date))

const hasQuantityOrdered = (summary: OrderSummary) => summary.quantityTotal !== null

const computePercentAllocated = (summary: OrderSummary) => {
  const allocated = summary.eacsAllocated ?? 0
  const total = summary.quantityTotal ? summary.quantityTotal * 1_000_000 : 0
  if (allocated === 0 || total === 0) {
    return 0
  }
  return (allocated / total) * 100
}

defineProps<{ orderSummaries: OrderSummary[] }>()
</script>
