import { RouteRecordRaw, RouteLocation } from "vue-router"
import { adminRoute } from "@/services/auth/authorized.route-guard"
import { AppPortal } from "@common/models/models"
import AdminAccountsView from "@/modules/admin/components/AdminAccountsView.vue"
import AdminAccountView from "@/modules/admin/components/AdminAccountView.vue"
import AdminLayout from "@/modules/admin/components/AdminLayout.vue"
import AdminPortfoliosView from "@/modules/admin/components/AdminPortfoliosView.vue"
import AdminAccountAssetsTable from "@/modules/admin/components/AdminAccountAssetsTable.vue"
import AdminAccountAuditSummaryTable from "@/modules/admin/components/AdminAccountAuditSummaryTable.vue"
import AdminAccountConfig from "@/modules/admin/components/AdminAccountConfig.vue"
import AdminAccountContact from "@/modules/admin/components/AdminAccountContact.vue"
import AdminAccountUsers from "@/modules/admin/components/AdminAccountUsers.vue"
import AdminOrdersTable from "@/modules/admin/components/AdminOrdersTable.vue"
import AdminAccountSupplier from "@/modules/admin/components/AdminAccountSupplier.vue"
import AdminPortfolioView from "@/modules/admin/components/AdminPortfolioView.vue"
import AdminPortfolioAllocations from "@/modules/admin/components/AdminPortfolioAllocations.vue"
import AdminPortfolioCreate from "@/modules/admin/components/AdminPortfolioCreate.vue"
import AdminPortfolioSuppliers from "@/modules/admin/components/AdminPortfolioSuppliers.vue"
import AdminAccountCreate from "@/modules/admin/components/AdminAccountCreate.vue"
import WcBaseAdminView from "@/modules/admin/components/WcBaseAdminView.vue"
import AdminPortfolioConfig from "@/modules/admin/components/AdminPortfolioConfig.vue"
import AdminPortfolioStakes from "@/modules/admin/components/AdminPortfolioStakes.vue"
import AdminStoriesView from "@/modules/admin/components/AdminStoriesView.vue"
import AdminStoryView from "@/modules/admin/components/AdminStoryView.vue"
import AdminStoryAssets from "@/modules/admin/components/AdminStoryAssets.vue"
import AdminStoryCreate from "@/modules/admin/components/AdminStoryCreate.vue"
import AdminStoryConfig from "@/modules/admin/components/AdminStoryConfig.vue"
import AdminAccountStakes from "@/modules/admin/components/AdminAccountStakes.vue"
import AdminAssetsView from "@/modules/admin/components/AdminAssetsView.vue"
import AdminAssetView from "./components/AdminAssetView.vue"
import AdminAssetDocuments from "@/modules/admin/components/AdminAssetDocuments.vue"
import AdminAssetInternalNotes from "@/modules/admin/components/AdminAssetInternalNotes.vue"
import AdminAssetAudits from "@/modules/admin/components/AdminAssetAudits.vue"
import AdminAssetVersions from "@/modules/admin/components/AdminAssetVersions.vue"
import AdminAssetTransactions from "@/modules/admin/components/AdminAssetTransactions.vue"
import AssetMetadata from "@/modules/asset/components/AssetMetadata.vue"
import AssetMetadataEdit from "@/modules/asset/components/AssetMetadataEdit.vue"
import scalarFromVectorOrScalar from "@/utils/scalarFromVectorOrScalar"

const accountIdProp = (route: RouteLocation) => ({ accountId: parseInt(scalarFromVectorOrScalar(route.params.accountId)) })
const portfolioIdProp = (route: RouteLocation) => ({ portfolioId: parseInt(scalarFromVectorOrScalar(route.params.portfolioId)) })
const storyIdProp = (route: RouteLocation) => ({ storyId: parseInt(scalarFromVectorOrScalar(route.params.storyId)) })
const assetIdProp = (route: RouteLocation) => ({ assetId: parseInt(scalarFromVectorOrScalar(route.params.assetId)) })
const selectedAssetIdProp = (route: RouteLocation) => ({ selectedAssetId: parseInt(scalarFromVectorOrScalar(route.params.assetId)) })

export const adminRoutes: Array<RouteRecordRaw> = [
  {
    path: "/admin",
    name: "wc-admin-home",
    component: WcBaseAdminView,
    redirect: { name: "wc-admin-accounts" },
    beforeEnter: adminRoute,
    meta: { layout: AdminLayout, appPortal: AppPortal.AdminPortal },
    children: [
      {
        path: "accounts",
        name: "wc-admin-accounts",
        component: AdminAccountsView,
        children: [
          {
            name: "wc-admin-account-new",
            path: "new",
            component: AdminAccountCreate,
          },
          {
            name: "wc-admin-account",
            path: ":accountId(\\d+)",
            component: AdminAccountView,
            props: accountIdProp,
            redirect: { name: "wc-admin-account-users" },
            children: [
              { name: "wc-admin-account-users", path: "users", component: AdminAccountUsers, props: accountIdProp },
              { name: "wc-admin-account-orders", path: "orders", component: AdminOrdersTable, props: accountIdProp },
              { name: "wc-admin-account-stakes", path: "stakes", component: AdminAccountStakes, props: accountIdProp },
              { name: "wc-admin-account-assets", path: "assets", component: AdminAccountAssetsTable, props: accountIdProp },
              { name: "wc-admin-account-audit-summary", path: "audit-summary", component: AdminAccountAuditSummaryTable, props: accountIdProp },
              { name: "wc-admin-account-config", path: "basic", component: AdminAccountConfig, props: accountIdProp },
              { name: "wc-admin-account-contact", path: "contact", component: AdminAccountContact, props: accountIdProp },
              { name: "wc-admin-account-supplier", path: "supplier", component: AdminAccountSupplier, props: accountIdProp },
            ],
          },
        ],
      },
      {
        path: "portfolios",
        name: "wc-admin-portfolios",
        component: AdminPortfoliosView,
        children: [
          {
            name: "wc-admin-portfolio-new",
            path: "new",
            component: AdminPortfolioCreate,
          },
          {
            name: "wc-admin-portfolio",
            path: ":portfolioId(\\d+)",
            component: AdminPortfolioView,
            redirect: { name: "wc-admin-portfolio-allocations" },
            props: portfolioIdProp,
            children: [
              { name: "wc-admin-portfolio-allocations", path: "allocations", component: AdminPortfolioAllocations, props: portfolioIdProp },
              { name: "wc-admin-portfolio-config", path: "details", component: AdminPortfolioConfig, props: portfolioIdProp },
              { name: "wc-admin-portfolio-suppliers", path: "suppliers", component: AdminPortfolioSuppliers, props: portfolioIdProp },
              { name: "wc-admin-portfolio-orders", path: "orders", component: AdminOrdersTable, props: portfolioIdProp },
              { name: "wc-admin-portfolio-stakes", path: "stakes", component: AdminPortfolioStakes, props: portfolioIdProp },
            ],
          },
        ],
      },
      {
        path: "stories",
        name: "wc-admin-stories",
        component: AdminStoriesView,
        children: [
          {
            name: "wc-admin-story-new",
            path: "new",
            component: AdminStoryCreate,
          },
          {
            name: "wc-admin-story",
            path: ":storyId(\\d+)",
            component: AdminStoryView,
            props: storyIdProp,
            redirect: { name: "wc-admin-story-config" },
            children: [
              { name: "wc-admin-story-config", path: "details", component: AdminStoryConfig, props: storyIdProp },
              { name: "wc-admin-story-assets", path: "assets", component: AdminStoryAssets, props: storyIdProp },
            ],
          },
        ],
      },
      {
        path: "assets",
        name: "wc-admin-assets",
        component: AdminAssetsView,
        props: selectedAssetIdProp,
        children: [
          {
            name: "wc-admin-asset",
            path: ":assetId(\\d+)",
            component: AdminAssetView,
            props: assetIdProp,
            redirect: { name: "wc-admin-asset-metadata" },
            children: [
              { name: "wc-admin-asset-metadata", path: "metadata", component: AssetMetadata },
              { name: "wc-admin-asset-metadata-edit", path: "edit", component: AssetMetadataEdit },
              { name: "wc-admin-asset-internal-notes", path: "internal-notes", component: AdminAssetInternalNotes },
              { name: "wc-admin-asset-documents", path: "documents", component: AdminAssetDocuments },
              { name: "wc-admin-asset-audits", path: "audits", component: AdminAssetAudits },
              { name: "wc-admin-asset-versions", path: "versions", component: AdminAssetVersions },
              { name: "wc-admin-asset-activity", path: "activity", component: AdminAssetTransactions },
            ],
          },
        ],
      },
    ],
  },
]
