<template>
  <table class="w-full">
    <thead>
      <tr class="border-b border-sagetone">
        <th class="text-left">ID</th>
        <th class="text-left">Buyer</th>
        <th class="text-right">Weight</th>
        <th class="text-right">Share</th>
        <th></th>
      </tr>
    </thead>
    <tbody class="divide-y divide-sagetone">
      <tr v-if="!stakesAndAccounts.length">
        <td colspan="5" class="px-4 py-2 text-left">
          <p v-if="!isLoading">No stakes.</p>
          <Icon v-else icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
        </td>
      </tr>
      <tr v-for="[stake, account] in stakesAndAccounts" :key="stake.accountId">
        <td class="px-4 py-2">{{ portfolio.displayId }}-{{ account.id }}</td>
        <td class="max-w-[20ch] truncate px-4 py-2 text-left underline">
          <router-link :to="{ name: 'wc-admin-account-stakes', params: { accountId: stake.accountId } }">
            {{ account.name }}
          </router-link>
        </td>
        <td class="px-4 py-2 text-right">{{ stake.weight }}</td>
        <td class="px-4 py-2 text-right">
          <template v-if="totalWeight > 0"> {{ ((stake.weight * 100) / totalWeight).toFixed(2) }}% </template>
        </td>
        <td class="">
          <button class="float-right text-error disabled:cursor-not-allowed disabled:text-sagetone" @click="removeStake(stake)">
            <Icon icon="wc-carbon:close-outline" />
            Remove
          </button>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="4" class="pt-4">
          <button class="text-blue-70 disabled:cursor-not-allowed disabled:text-sagetone" data-cy="spoof-button" @click="isAddStakeDialogOpen = true">
            <Icon icon="wc-carbon:add" />
            Add Stake
          </button>
        </td>
      </tr>
    </tfoot>
  </table>

  <WcModal :is-open="isAddStakeDialogOpen" header="Add Stake" @update:is-open="(isOpen) => (isAddStakeDialogOpen = isOpen)">
    <template #panel>
      <form ref="formRef" @submit.prevent="addStake">
        <div class="flex flex-col gap-4">
          <label>
            Buyer
            <select name="accountId" class="p-2" required :disabled="isSaving">
              <option v-for="a in filteredAccounts" :key="a.id" :value="a.id">{{ a.name }} ({{ a.id }})</option>
            </select>
          </label>

          <label>
            Weight*
            <input type="number" name="weight" required :disabled="isSaving" min="1" />
          </label>
        </div>
      </form>
    </template>
    <template #footer>
      <WcButton text="Cancel" variant="secondary" :is-disabled="isSaving" size="small" @click="isAddStakeDialogOpen = false" />
      <WcButton text="Add" :is-disabled="isSaving" size="small" @click="formRef?.requestSubmit()" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue"
import { Icon } from "@iconify/vue"
import { WcButton } from "@/components/button"
import WcModal from "@/components/WcModal.vue"
import _ from "lodash"
import { PortfolioStake } from "@common/models/order"
import { usePortfolioService } from "@/services/service-container"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import { useAdminPortfoliosStore } from "../adminPortfolios.state"
import { Account, AccountType } from "@common/models/models"
import { TYPE, useToast } from "vue-toastification"

const props = defineProps<{
  portfolioId: number
}>()

const adminAccountsStore = useAdminAccountsStore()
const adminPortfoliosStore = useAdminPortfoliosStore()
const portfolioService = usePortfolioService()
const toast = useToast()

const formRef = ref()
const isLoading = ref<boolean>(false)
const stakes = ref<Array<PortfolioStake>>([])

// This is only used in a context where the portfolioId exists
const portfolio = computed(() => adminPortfoliosStore.getPortfolioById(props.portfolioId)!)

watchEffect(async () => {
  isLoading.value = true
  try {
    stakes.value = await portfolioService.listStakes({ portfolioId: props.portfolioId })
  } finally {
    isLoading.value = false
  }
})

const stakesAndAccounts = computed<Array<[PortfolioStake, Account]>>(() =>
  stakes.value.map((s) => [s, adminAccountsStore.getAccountById(s.accountId)!])
)

const totalWeight = computed(() => stakes.value.reduce((sum, supplier) => (sum += supplier.weight), 0))

const filteredAccounts = computed(() =>
  _.sortBy(
    adminAccountsStore.accounts.filter((a) => a.type !== AccountType.system),
    (a) => a.name
  )
)

const isAddStakeDialogOpen = ref<boolean>(false)
const isSaving = ref<boolean>(false)
const addStake = async (e: Event) => {
  const formEl = e.target! as HTMLFormElement
  let newStake

  isSaving.value = true
  const toastId = toast("Adding stake…", { id: "PortfolioStakes", type: TYPE.DEFAULT, timeout: false })
  try {
    newStake = await portfolioService.createStake(props.portfolioId, formEl.accountId.value, formEl.weight.value)
  } catch (err) {
    toast.update(toastId, { content: `Error: ${err}`, options: { type: TYPE.ERROR } })
    return
  } finally {
    isSaving.value = false
  }

  toast.update(toastId, { content: "Success!", options: { type: TYPE.SUCCESS, timeout: 1000 } })
  stakes.value.push(newStake)
  isAddStakeDialogOpen.value = false
  formEl.reset()
}

const removeStake = async (stake: PortfolioStake) => {
  isSaving.value = true
  const toastId = toast("Removing stake…", { id: "PortfolioStakes", type: TYPE.DEFAULT, timeout: false })
  try {
    await portfolioService.deleteStake(props.portfolioId, stake.accountId)
  } catch (err) {
    toast.update(toastId, { content: `Error: ${err}`, options: { type: TYPE.ERROR } })
    return
  } finally {
    isSaving.value = false
  }

  toast.update(toastId, { content: "Success!", options: { type: TYPE.SUCCESS, timeout: 1000 } })
  _.remove(stakes.value, (s) => s.accountId == stake.accountId)
}
</script>

<style scoped lang="scss">
button {
  @apply flex gap-2 items-center;
}

thead th {
  @apply font-normal px-4 py-2;
}

input[type="text"],
input[type="number"],
textarea,
select {
  @apply invalid:border-error;
}
</style>
