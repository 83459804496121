function hasTimeZone(dateString: string) {
  const timeZoneRegex = /(?:Z|[+-](?:2[0-3]|[01]\d):[0-5]\d)$/
  return timeZoneRegex.test(dateString)
}

export function parseUTCTimestamp(dateString: string) {
  if (!hasTimeZone(dateString)) {
    dateString += "Z" // treat naive timestamps as UTC
  }
  return new Date(dateString)
}
