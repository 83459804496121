<template>
  <AppPage>
    <AppPageHeader> Manage Your Accounts </AppPageHeader>
    <AppPageContent>
      <AppPageSection>
        <AppPageSectionHeader>Personal Account</AppPageSectionHeader>
        <div class="w-full rounded border border-neutral-30 p-8">
          <div class="mb-3 flex justify-between">
            <div class="text-body-2 relative">{{ personalAccount?.name }}</div>
            <div>
              <WcButton
                variant="secondary"
                button-classes="ph-no-capture"
                text="View Details"
                type="anchor"
                @click="goToDetails(personalAccount?.id, 'personal')" />
            </div>
          </div>
          <div class="flex flex-col gap-2.5">
            <span class="text-subheading-1">Email</span>
            <span class="text-body-2 mb-12">{{ personalAccount?.contact_info?.email || "None Provided" }}</span>
          </div>
        </div>
      </AppPageSection>
      <AppPageSection>
        <AppPageSectionHeader class="flex w-full flex-wrap justify-between gap-4">
          Organizations
          <WcButton text="Create An Organization" button-classes="ph-no-capture" @click="openOrganizationModal" />
        </AppPageSectionHeader>
        <div v-for="account in organizationAccounts" :key="account.id" class="mb-5 w-full rounded border border-neutral-30 p-8">
          <div class="mb-3 flex justify-between">
            <div class="text-body-2 relative">{{ account.name }}</div>
            <div>
              <WcButton text="View Details" type="anchor" variant="secondary" @click="goToDetails(account.id, 'organization')" />
            </div>
          </div>
          <div class="flex flex-col gap-2.5">
            <span class="text-subheading-1">Address</span>
            <div v-if="account.contact_info" class="flex flex-col">
              <span>{{ account?.contact_info?.street_1 }}</span>
              <span>{{ account?.contact_info?.street_2 }}</span>
              <span
                >{{ account?.contact_info?.city }}, {{ account?.contact_info?.state_province }} {{ account?.contact_info?.postal_code }}
                {{ account?.contact_info?.country }}</span
              >
            </div>
            <span v-else class="text-body-2 mb-12">{{ "None Provided" }}</span>
          </div>
        </div>
      </AppPageSection>
      <WcModal :is-open="isOrganizationModalOpen" header="Create an Organization" @update:is-open="handleUpdateIsOpen">
        <template #panel>
          <form ref="formRef" @submit.prevent="createOrganization()">
            <div class="gap-4">
              <TextInput
                v-model="accountName"
                class="mb-4"
                label="Legal Name of Organization"
                required
                autocomplete="off"
                @click="captureLegalNameInput" />
              <TextInput
                v-model="contactInfo.email"
                class="mb-4"
                label="Contact Email of Organization"
                type="email"
                @click="captureContactEmailInput" />
              <div class="text-subheading-1 mb-2 mt-8">Street Address of Organization</div>
              <TextInput v-model="contactInfo.street_1" class="mb-4" label="Address Line 1" required @click="captureStreetAddressInput" />
              <TextInput v-model="contactInfo.street_2" class="mb-4" label="Address Line 2" @click="captureAddressLineTwoInput" />
              <div class="grid grid-cols-6 items-end gap-4">
                <TextInput v-model="contactInfo.city" class="col-span-4" label="City" required @click="captureCityInput" />
                <TextInput
                  v-model="contactInfo.state_province"
                  class="col-span-2"
                  label="State/Province"
                  required
                  @click="captureStateProvinceInput" />
                <TextInput v-model="contactInfo.postal_code" class="col-span-3" label="Postal Code" required @click="capturePostalCodeInput" />
                <TextInput v-model="contactInfo.country" class="col-span-3" label="Country" required @click="captureCountryInput" />
              </div>
            </div>
          </form>
        </template>
        <template #footer>
          <WcButton class="basis-full sm:basis-auto" variant="secondary" text="Cancel" size="small" @click="closeOrganizationModal()" />
          <WcButton
            class="basis-full sm:basis-auto"
            :is-disabled="isCreateOrganizationButtonDisabled"
            text="Create Organization"
            size="small"
            @click="formRef?.requestSubmit()" />
        </template>
      </WcModal>
    </AppPageContent>
  </AppPage>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from "vue"
import { useRouter } from "vue-router"
import posthog from "posthog-js"
import { WcButton } from "@/components/button"
import { AppPage, AppPageContent, AppPageSection, AppPageHeader, AppPageSectionHeader } from "@/components/layout"
import TextInput from "@/components/ui/TextInput.vue"
import WcModal from "@/components/WcModal.vue"
import { useMainStore } from "@/store"
import { Account, AccountContactInfo } from "@common/models/models"
import { useAccountsService } from "@/services/service-container"
import { TYPE, useToast } from "vue-toastification"

const accountsService = useAccountsService()
const store = useMainStore()
const router = useRouter()
const toast = useToast()

const formRef = ref()
const accountName = ref<string>("")
const contactInfo = ref<AccountContactInfo>({} as AccountContactInfo)

// PostHog Events
const captureViewPersonalDetailsClickEvent = () => posthog.capture("Clicked on the 'View Details' button - Personal Account - Accounts Manage View")
const captureCreateAnOrganizationClickEvent = () => posthog.capture("Clicked on the 'Create an Organization' button - Accounts Manage View")
const captureViewOrganizationDetailsClickEvent = () => posthog.capture("Clicked on the 'View Details' button - Organizations - Accounts Manage View")
const captureLegalNameInput = () =>
  posthog.capture("Clicked on the 'Organization's Legal Name' input - Create an Organization - Accounts Manage View")
const captureContactEmailInput = () =>
  posthog.capture("Clicked on the 'Organization's Contact Email' input - Create an Organization - Accounts Manage View")
const captureStreetAddressInput = () =>
  posthog.capture("Clicked on the 'Organization's Street Address' input - Create an Organization - Accounts Manage View")
const captureAddressLineTwoInput = () => posthog.capture("Clicked on the 'Address Line 2' input - Create an Organization - Accounts Manage View")
const captureCityInput = () => posthog.capture("Clicked on 'Organization's City' - Create an Organization - Accounts Manage View")
const captureStateProvinceInput = () =>
  posthog.capture("Clicked on the 'Organization's State/Province' input - Create an Organization - Accounts Manage View")
const capturePostalCodeInput = () =>
  posthog.capture("Clicked on the 'Organization's Postal Code' input - Create an Organization - Accounts Manage View")
const captureCountryInput = () => posthog.capture("Clicked on the 'Organization's Country' input - Create an Organization - Accounts Manage View")
const captureCancelButtonClick = () => posthog.capture("Clicked on the 'Cancel' button - Accounts Manage View")
const captureSubmitCreateOrganizationEvent = () => posthog.capture("Submitted the 'Create Organization' form - Accounts Manage View")
const captureSubmitCreateOrganizationEventError = () =>
  posthog.capture("Error when attempting to submit the 'Create Organization' form - Accounts Manage View")

const isOrganizationModalOpen = ref<boolean>(false)
const openOrganizationModal = () => {
  captureCreateAnOrganizationClickEvent()
  isOrganizationModalOpen.value = true
}
const closeOrganizationModal = () => {
  isOrganizationModalOpen.value = false
  captureCancelButtonClick()
}
const handleUpdateIsOpen = (isOpen: boolean) => {
  isOrganizationModalOpen.value = isOpen
}
const isCreateOrganizationButtonDisabled = computed(() => {
  return (
    !accountName.value ||
    !contactInfo.value.street_1 ||
    !contactInfo.value.city ||
    !contactInfo.value.state_province ||
    !contactInfo.value.postal_code ||
    !contactInfo.value.country
  )
})

const goToDetails = (accountId: number, accountType: string) => {
  router.push(`/accounts/${accountId}`)
  if (accountType === "personal") {
    captureViewPersonalDetailsClickEvent()
  } else {
    captureViewOrganizationDetailsClickEvent()
  }
}

const createOrganization = async () => {
  const toastId = toast("Creating organization…", { id: "AccountsManageView.createOrganization", type: TYPE.DEFAULT, timeout: false })

  try {
    const newAccount = await accountsService.addAccount(accountName.value, contactInfo.value)
    isOrganizationModalOpen.value = false
    toast.dismiss(toastId)
    store.accounts.push(newAccount)
    captureSubmitCreateOrganizationEvent()
  } catch (error) {
    toast.update(toastId, { content: `Failed to create organization: ${error}`, options: { type: TYPE.ERROR } })
    captureSubmitCreateOrganizationEventError()
  }
}

const personalAccount = computed(() => {
  return store.accounts.find((account) => account.type === "individual") as Account
})

const organizationAccounts = computed(() => {
  return store.accounts.filter((account) => account.type === "organization")
})

onMounted(async () => {
  store.accounts = await accountsService.listMyAccounts()
})
</script>
