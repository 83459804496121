<template>
  <table class="w-full">
    <thead>
      <tr class="border-b border-sagetone">
        <th class="text-left">Account</th>
        <th class="text-right">Desired</th>
        <th class="text-right">Allocated EACs</th>
        <th class="text-right">Completion</th>
      </tr>
    </thead>
    <tbody class="divide-y divide-sagetone">
      <tr v-if="allocations.length == 0">
        <td colspan="3" class="py-2 text-left">
          <p v-if="!isLoading">No allocations.</p>
          <Icon v-else icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
        </td>
      </tr>
      <tr v-for="allocation in allocations" :key="allocation.account.id">
        <td class="max-w-[20ch] truncate py-2 text-left underline">
          <router-link :to="{ name: 'wc-admin-account-orders', params: { accountId: allocation.account.id } }">
            {{ allocation.account.name }}
          </router-link>
        </td>
        <td v-if="allocation.quantity" class="py-2 text-right">{{ allocation.quantity.toLocaleString() }}</td>
        <td v-if="allocation.percent" class="py-2 text-right">{{ allocation.percent }}%</td>
        <td class="py-2 text-right">{{ allocation.allocation.eacsAllocated.toLocaleString() }}</td>
        <td v-if="allocation.quantity" class="py-2 text-right">
          {{ ((allocation.allocation.eacsAllocated / allocation.quantity) * 100).toFixed(2) }}%
        </td>
        <td v-if="allocation.percent" class="py-2 text-right">N/A</td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { watch, ref } from "vue"
import { usePortfolioService } from "@/services/service-container"
import { PortfolioAllocation } from "@common/models/order"
import { Icon } from "@iconify/vue"

const props = defineProps<{
  portfolioId: number
}>()

const portfolioService = usePortfolioService()

const isLoading = ref(true)
const allocations = ref<PortfolioAllocation[]>([])

watch(
  () => props.portfolioId,
  async () => {
    try {
      isLoading.value = true
      allocations.value = await portfolioService.getAllocations(props.portfolioId)
    } catch (exc) {
      console.error(exc)
    } finally {
      isLoading.value = false
    }
  },
  { immediate: true }
)
</script>
