<template>
  <div class="mb-5 flex items-baseline justify-between gap-2">
    <h3 class="text-subheading-1 min-w-0 break-words" :class="{ 'pr-6': isSelectionMode }">{{ group.name }}</h3>
    <WcMenu v-if="!isSelectionMode" class="relative -top-0.5 -mr-1.5" opener-class="size-[18px]">
      <WcMenuItem text="Edit asset group" icon="wc-carbon:edit" @click="$emit('edit', group.id)" />
      <WcMenuItem text="Delete" icon="wc-carbon:trash-can" color="alert" @click="$emit('delete', group)" />
    </WcMenu>
  </div>
  <div class="mb-8 grid grow grid-cols-3 gap-4">
    <div class="col-span-1">
      <div class="text-subheading-large-bold">{{ group.deviceIds.length }}</div>
      <div class="text-body-3">Total assets</div>
    </div>
    <div class="col-span-2">
      <div>
        <span class="text-subheading-large-bold">{{ getAssetGroupTotalEacs(group).quantity + getAssetGroupTotalEacs(group).unit }}</span>
      </div>
      <div class="text-body-3">Total EACs created</div>
    </div>
  </div>
  <div class="text-caption grid grow-0 grid-cols-2 gap-0 text-hint">
    <div>Created: {{ formatDate(group.createdTime) }}</div>
    <div>Last updated: {{ formatDate(group.updatedTime) }}</div>
  </div>
</template>

<script setup lang="ts">
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"
import { WcMenu, WcMenuItem } from "@/components/menu"
import type { AssetGroupWithSummary } from "@/modules/asset/asset.service"
import { netEacsFromValues } from "@/modules/registry/registry.utils"

const formatter = new Intl.DateTimeFormat("en", { dateStyle: "short" })
const formatDate = (date: string) => formatter.format(new Date(date))

defineProps<{
  group: AssetGroupWithSummary
  isSelectionMode?: boolean
}>()

defineEmits<{
  edit: [groupId: number]
  delete: [group: AssetGroupWithSummary]
}>()

const getAssetGroupTotalEacs = (group: AssetGroupWithSummary) => {
  if (group.summary.eacs.sum.length === 0) return { quantity: "0", unit: "" }
  const netEacs = netEacsFromValues(group.summary.eacs.sum)
  return getFormattedEacQuantity(netEacs, EacMeasurementParameter.UnlabeledShort)
}
</script>
