import type { OpenApiStore } from "./openapi.state"

interface BaseComponent {
  type: string
  name: string
  title: string
  required?: boolean
}

interface TextComponent extends BaseComponent {
  type: "text" | "date" | "datetime-local"
  min?: number
  max?: number
  minLength?: number
  maxLength?: number
  inputmode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search"
  pattern?: string
  placeholder?: string
}

interface CheckboxComponent extends BaseComponent {
  type: "checkbox"
}

interface SelectOption {
  label: string
  value: string
}

interface SelectComponent extends BaseComponent {
  type: "select"
  options: SelectOption[]
}

export interface FormComponent extends BaseComponent {
  type: "form"
  fields: Component[]
}

type Component = TextComponent | CheckboxComponent | SelectComponent | FormComponent

function prepareFormField(openapi: OpenApiStore, pointer: string): Component {
  const schema = openapi.dereferenceUri(pointer)
  if (schema === null) {
    return { type: "form", name: "", title: "", fields: [] }
  }

  if ("properties" in schema) {
    const required = schema.required ?? []
    return {
      type: "form",
      name: "",
      title: schema.title,
      fields: Object.keys(schema.properties).map((key) => {
        const subfield = prepareFormField(openapi, `${schema.$id}/properties/${key}`)
        subfield.name = key
        subfield.required = required.includes(key)
        return subfield
      }),
    }
  } else if (schema.type == "string") {
    if (schema.enum !== undefined) {
      return { type: "select", name: "", title: schema.title, options: schema.enum.map((value: string) => ({ label: value, value })) }
    } else if (schema.format == "date") {
      return { type: "date", name: "", title: schema.title }
    } else if (schema.format == "date-time") {
      return { type: "datetime-local", name: "", title: schema.title }
    } else {
      return {
        type: "text",
        name: "",
        title: schema.title,
        minLength: schema.minLength,
        maxLength: schema.maxLength,
        pattern: schema.pattern,
        placeholder: schema.examples?.[0],
      }
    }
  } else if (schema.type == "integer" || schema.type == "number") {
    return {
      type: "text",
      name: "",
      title: schema.title,
      min: schema.minimum ?? schema.exclusiveMinimum,
      max: schema.maximum ?? schema.exclusiveMaximum,
      inputmode: schema.type == "integer" ? "numeric" : "decimal",
      pattern: schema.type == "integer" ? "[0-9]*" : "(\\+|-)?[0-9]*.[0-9]*",
    }
  } else if (schema.type == "boolean") {
    return { type: "checkbox", name: "", title: schema.title }
  } else if ("anyOf" in schema) {
    // Hack! We assume the first option is representative of the full range of
    // fields in the rest of the options. We're also making this assumption on
    // the backend in the CSV parsing code.
    const field = prepareFormField(openapi, `${pointer}/anyOf/0`)

    if (field.type == "form") {
      // Since the first subform might have required fields that aren't required
      // in the other subforms, we need to remove the required attribute and let
      // the backend determine if the field is truly required.
      for (const f of field.fields) {
        f.required = false
      }
    }

    if (schema.title !== undefined) {
      field.title = schema.title
    }

    if (field.type === "text" && schema?.examples?.[0] !== undefined) {
      field.placeholder = schema.examples[0]
    }

    return field
  } else {
    throw Error("unexpected form type")
  }
}

export function prepareForm(openapi: OpenApiStore, pointer: string): FormComponent {
  return prepareFormField(openapi, pointer) as FormComponent
}
