<template>
  <template v-if="account.users !== undefined">
    <ul class="divide-y divide-neutral-30">
      <li v-for="user in account.users" :key="user.id" class="flex items-center justify-between py-2">
        <div>
          {{ user.email }}
        </div>
        <div class="flex gap-4">
          <button class="text-blue-70" data-cy="spoof-button" @click="spoof(user)">
            <Icon icon="wc-carbon:user-multiple" />
            Spoof
          </button>
          <button
            class="text-error disabled:cursor-not-allowed disabled:text-sagetone"
            :disabled="account.type != 'organization'"
            @click="removeUser(user)">
            <Icon icon="wc-carbon:close-outline" />
            Remove
          </button>
        </div>
      </li>
      <li v-if="account.users.length == 0" class="py-2">No users.</li>
      <li class="py-2">
        <button
          class="text-blue-70 disabled:cursor-not-allowed disabled:text-sagetone"
          data-cy="spoof-button"
          :disabled="account.type != 'organization'"
          @click="isAddUserDialogOpen = true">
          <Icon icon="wc-carbon:add" />
          Add User
        </button>
      </li>
    </ul>
  </template>

  <Icon v-else icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />

  <WcModal :is-open="isAddUserDialogOpen" header="Add User" @update:is-open="(isOpen) => (isAddUserDialogOpen = isOpen)">
    <template #panel>
      <form ref="formRef" @submit.prevent="addUser">
        <div class="flex flex-col gap-4">
          <label>
            User Email Address
            <input type="email" name="emailAddress" autocomplete="off" placeholder="example@example.com" required />
          </label>

          <p class="text-sagetone">Note: if the user does not currently exist in the system, they will be created.</p>
        </div>
      </form>
    </template>
    <template #footer>
      <WcButton text="Cancel" variant="secondary" size="small" @click="isAddUserDialogOpen = false" />
      <WcButton text="Add" size="small" @click="formRef?.requestSubmit()" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import { computed, ref } from "vue"
import { Icon } from "@iconify/vue"
import { useAuthService } from "@/services/service-container"
import { useRouter } from "vue-router"
import { User } from "@common/models/models"
import { WcButton } from "@/components/button"
import WcModal from "@/components/WcModal.vue"
import { useMainStore } from "@/store"

const props = defineProps<{
  accountId: number
}>()

const adminAccountsStore = useAdminAccountsStore()
const store = useMainStore()
const authService = useAuthService()
const router = useRouter()

const formRef = ref()

const account = computed(() => adminAccountsStore.getAccountById(props.accountId)!)

const spoof = async (user: User) => {
  // Unspoof first to make sure we retain the correct original user
  if (store.isSpoof) {
    await authService.unspoof()
  }

  // Spoof as the desired user
  await authService.spoof(user.id, account.value.id)
  await router.push({ name: "wc-landing" })
}

const isAddUserDialogOpen = ref<boolean>(false)
const addUser = async (e: Event) => {
  const formEl = e.target! as HTMLFormElement
  await adminAccountsStore.addUser(account.value.id, formEl.emailAddress.value)

  // refresh the main account list so the new user's individual-type account
  // shows up in the list
  // TODO: do we really need to fetch the WHOLE list!?
  await adminAccountsStore.loadAccounts()

  isAddUserDialogOpen.value = false
  formEl.reset()
}

const removeUser = async (user: User) => {
  if (confirm(`Remove '${user.email}' from '${account.value.name}'? This will not delete the user, but just remove their access to this account.`)) {
    await adminAccountsStore.removeUser(account.value.id, user.id)
  }
}
</script>

<style scoped lang="scss">
button {
  @apply flex gap-2 items-center;
}

input[type="email"] {
  @apply invalid:border-error;
}
</style>
