import { Order, OrderCreate, OrderSummary, OrderSummaryDetails, OrderUpdate } from "@common/models/order"
import ApiFetcher from "@/services/api-fetcher"

export default class OrdersService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }
  get = async (id: number): Promise<Order> => {
    const endpoint = `/orders/${id}`
    return await this.fetcher.httpGet<Order>(endpoint, {})
  }
  listOrders = async (): Promise<Array<Order>> => {
    const endpoint = "/orders"
    // TODO add Start and end date
    return await this.fetcher.httpGet<Array<Order>>(endpoint, {})
  }
  listOrdersAdmin = async (): Promise<Array<Order>> => {
    const endpoint = "/orders/admin"
    return await this.fetcher.httpGet<Array<Order>>(endpoint, {})
  }
  addOrder = async (orderCreate: OrderCreate) => {
    const endpoint = "/orders"
    try {
      const updated = await this.fetcher.httpPost<Order>(endpoint, orderCreate)
      return new Promise<Order>((resolve) => {
        resolve(updated)
      })
    } catch {
      return new Promise<Order>((resolve, reject) => {
        reject(new Error("Unable to add an order"))
      })
    }
  }
  updateOrder = async (orderUpdate: OrderUpdate) => {
    const endpoint = `/orders/${orderUpdate.id}`
    return await this.fetcher.httpPut<Order>(endpoint, orderUpdate)
  }
  getAgreementLink = async (id: number) => {
    const endpoint = `/orders/${id}/agreement`
    return await this.fetcher.httpGet<{ url: string }>(endpoint, {})
  }
  getOrdersSummary = async () => {
    const endpoint = "/orders/summary"
    return await this.fetcher.httpGet<OrderSummary[]>(endpoint, {})
  }
  getOrderDetail = async (portfolioId: number): Promise<OrderSummaryDetails> => {
    const endpoint = `/orders/summary/portfolio/${portfolioId}`
    return await this.fetcher.httpGet<OrderSummaryDetails>(endpoint, {})
  }
}
