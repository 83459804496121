<template>
  <div :id="chartId" class="bg-white dark:bg-blue-99"></div>
</template>

<script setup lang="ts">
// Libraries
import { format } from "date-fns"
import * as echarts from "echarts"
import { EChartsOption, EChartsType, XAXisComponentOption, DataZoomComponentOption } from "echarts"
import { onMounted, PropType, watch } from "vue"

// Models
import { LoadshapeSummaryRow } from "@common/models/loadshape"

// Services
import { VizHelper } from "@/components/visualizations/VizHelper"
import { useMainStore } from "@/store"

// ECharts doesn't export this type, so defining here
type CategoryAxisBaseOption = {
  data: Record<string, any>
}

const props = defineProps({
  width: { type: String },
  height: { type: String },
  data: { type: Object as PropType<Array<LoadshapeSummaryRow>>, required: true },
  isDaily: { type: Boolean, default: true },
})
const emit = defineEmits(["onChange"])

const store = useMainStore()

const chartId = "energy-line-chart-" + new Date().getMilliseconds()

const option: EChartsOption = {
  legend: {
    data: ["Energy", "Carbon"],
    inactiveColor: "#777",
    textStyle: {
      color: store.colors.text,
    },
    right: 0,
    top: 0,
  },
  grid: {
    left: 50,
    top: 80,
    right: 60,
    bottom: 80,
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      animation: false,
      type: "cross",
      lineStyle: {
        color: store.colors.text,
        width: 2,
        opacity: 1,
      },
    },
    valueFormatter: (value: any) => Math.round(value).toString(),
  },
  xAxis: {
    type: "category",
    data: [],
    axisLine: { lineStyle: { color: store.colors.grid } },
    axisLabel: {
      color: store.colors.text,
      fontSize: 10,
    },
    axisTick: {
      show: false,
    },
  },
  yAxis: [
    {
      type: "value",
      name: "kWh",
      scale: false,
      axisLine: { lineStyle: { color: store.colors.text } },
      axisLabel: {
        color: store.colors.text,
        formatter: (value: number) => Math.round(value).toString(),
        fontSize: 10,
      },
    },
    {
      type: "value",
      name: "kg",
      scale: false,
      axisLine: { lineStyle: { color: store.colors.text } },
      axisLabel: {
        color: store.colors.text,
        formatter: (value: number) => Math.round(value).toString(),
        fontSize: 10,
      },
    },
  ],
  dataZoom: {
    type: "slider",
    textStyle: {
      color: store.colors.text,
    },
    backgroundColor: store.colors.background,
    borderColor: store.colors.background,
    dataBackground: {
      lineStyle: {
        opacity: 1,
        color: store.colors.grid,
      },
    },
    fillerColor: "rgb(0,0,0,.03)",
    brushStyle: {
      color: store.colors.energy,
      borderColor: store.colors.energy,
      opacity: 0.2,
    },
    handleStyle: {
      color: store.colors.grid,
      borderColor: store.colors.grid,
      opacity: 0.7,
    },
    moveHandleStyle: {
      color: store.colors.grid,
      borderColor: store.colors.grid,
      opacity: 0.4,
    },
    emphasis: {
      handleStyle: {
        color: store.colors.grid,
        borderColor: store.colors.grid,
        opacity: 0.8,
      },
      moveHandleStyle: {
        color: store.colors.grid,
        borderColor: store.colors.grid,
        opacity: 0.5,
      },
    },
    selectedDataBackground: {
      lineStyle: {
        color: store.colors.grid,
        opacity: 1,
      },
      areaStyle: {
        color: store.colors.energy,
        opacity: 1,
      },
    },
    brushSelect: true,
  },
  series: [
    {
      name: "Energy",
      type: "bar",
      data: [],
      itemStyle: {
        color: store.colors.energy,
      },
    },
    {
      name: "Carbon",
      type: "line",
      step: "middle",
      symbol: "none",
      data: [],
      itemStyle: {
        color: store.colors.carbon,
        opacity: 0,
      },
      lineStyle: {
        color: store.colors.carbon,
        opacity: 1,
      },
      yAxisIndex: 1,
    },
  ],
}

let chart: EChartsType

const renderChart = (rows: Array<LoadshapeSummaryRow>) => {
  const dates = rows.map((h: LoadshapeSummaryRow) => {
    return props.isDaily ? format(h.datetime, "MM/dd/yy") : format(h.datetime, "MMM yy")
  })
  const _energy = rows.map((h: LoadshapeSummaryRow) => h.marketBasedEnergySumMwh * 1000)
  const _carbon = rows.map((h: LoadshapeSummaryRow) => h.marketBasedCarbonSumKgCo2)
  const intervals = 7
  const { ticks: energyTicks } = VizHelper.getAxisTicks(Math.min(..._energy), Math.max(..._energy), intervals)
  const { ticks: carbonTicks } = VizHelper.getAxisTicks(Math.min(..._carbon), Math.max(..._carbon), intervals)
  const energyMin = Math.min(...energyTicks)
  const energyMax = Math.max(...energyTicks)
  const carbonMax = Math.max(...carbonTicks)
  const carbonMin = Math.min(...carbonTicks)

  const yMax = energyMax > carbonMax ? energyMax : carbonMax
  const yMin = energyMin < carbonMin ? energyMin : carbonMin

  chart.setOption({
    xAxis: {
      data: dates,
    },
    yAxis: [
      {
        type: "value",
        min: yMin,
        max: yMax,
      },
      {
        type: "value",
        min: yMin,
        max: yMax,
      },
    ],
    series: [
      {
        // Find series by name
        name: "Energy",
        data: _energy,
      },
      {
        // Find series by name
        name: "Carbon",
        data: _carbon,
      },
    ],
  })
}

onMounted(() => {
  const chartDom = document.getElementById(chartId)
  if (!chartDom) {
    return
  }
  chart = echarts.init(chartDom, undefined, {
    width: props.width,
    height: props.height,
  })
  if (option) {
    chart.setOption(option)
  }
  chart.on("dataZoom", () => {
    const options = chart.getOption()
    const dataZooms = options.dataZoom as Array<DataZoomComponentOption>
    const xAxis = options.xAxis as Array<XAXisComponentOption>
    const { startValue, endValue } = dataZooms[0]
    const xAxisData = (xAxis[0] as CategoryAxisBaseOption).data
    const startDate = xAxisData[startValue as string]
    const endDate = xAxisData[endValue as string]
    emit("onChange", { startDate, endDate })
  })
  renderChart(props.data)
  new ResizeObserver(() => chart.resize()).observe(chartDom)
})

watch(
  () => props.data,
  (value: Array<LoadshapeSummaryRow>) => {
    renderChart(value)
  }
)
</script>
