<template>
  <aside class="sticky top-4 flex h-[calc(100vh-8rem)] w-[315px] flex-col bg-white dark:bg-blue-99 dark:text-white">
    <div class="border-b border-divider-dark p-6 pb-8">
      <div class="mb-5">
        <div class="text-body-3 text-black">Account</div>

        <Listbox v-model="selectedAccount" @update:model-value="switchAccount">
          <div class="text-body-2 relative mt-1">
            <ListboxButton
              class="focus-visible:ring-2/75 relative w-full cursor-pointer rounded border border-blue-90 bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-highlight focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:ring-offset-highlight">
              <span class="block truncate">{{ selectedAccount?.name }}</span>
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronIcon class="text-gray-400 size-5" aria-hidden="true" />
              </span>
            </ListboxButton>
            <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
              <ListboxOptions
                class="text-body-3 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
                <ListboxOption v-for="account in store.accounts" v-slot="{ selected }" :key="account.id" :value="account" as="template">
                  <li class="relative cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-neutral-20">
                    <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">{{ account.name }}</span>
                    <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3"><Icon icon="wc-carbon:checkmark" /></span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>

      <h5 class="text-subheading-2 mb-3">Date</h5>
      <nav class="flex-stretch flex">
        <button
          v-for="(dateFilter, key, index) in dateFilters"
          :key="key"
          class="grow justify-center border-y border-r py-2 text-sm"
          :class="[
            selectedDateFilter === key ? 'text-subheading-3 bg-darktone-lightbackground' : 'text-body-3 bg-white',
            {
              'rounded-l border-x': index === 0,
              'rounded-r': index === Object.keys(dateFilters).length - 1,
            },
          ]"
          @click="setDate(key)">
          {{ dateFilter.label }}
        </button>
      </nav>
      <div class="justify mt-4 flex justify-between gap-2">
        <div class="w-1/2">
          <label class="text-body-3 text-black">Start</label>
          <vue-flat-pickr
            v-model="startDate"
            class="!text-body-2 mt-1 w-full !border-sagetone !px-3 !py-2"
            :config="datePickerConfig"
            placeholder="Select date"
            name="date" />
        </div>
        <div class="w-1/2">
          <label class="text-body-3 text-black">End</label>
          <vue-flat-pickr
            v-model="endDate"
            class="!text-body-2 mt-1 w-full !border-sagetone !px-3 !py-2"
            :config="datePickerConfig"
            placeholder="Select date"
            name="date2" />
        </div>
      </div>
    </div>

    <div class="border-b border-divider-light px-6 py-4 dark:border-blue-90">
      <div class="mb-0.5 flex items-start justify-between">
        <h5 class="text-subheading-2 mb-2">Sites</h5>
        <button class="text-subheading-3 flex items-center" data-cy="button-site-add" @click="showSiteDialog">
          <Icon icon="wc-carbon:add" class="size-5" />
          Add Site
        </button>
      </div>
      <div class="flex items-center justify-between">
        <button v-if="!isAllSelected" class="text-subheading-2 flex items-center gap-1" data-cy="button-site-selectall" @click="selectAll()">
          <Icon icon="wc-carbon:checkbox" class="size-[22px]" />
          Select All
        </button>
        <button v-else class="text-subheading-2 flex items-center gap-1" data-cy="button-site-remove" @click="unselectAll()">
          <Icon icon="wc-carbon:checkbox-checked-filled" class="size-[22px]" />
          Unselect All
        </button>
        <div class="text-caption dark:text-highlight-yellow">{{ pendingSelectedAssetIds.length }} assets selected</div>
      </div>
    </div>
    <div class="grow overflow-y-scroll">
      <div v-if="sitesStore.isLoading" class="flex h-full items-center justify-center">
        <Icon icon="mdi:loading" class="size-20 animate-spin" />
      </div>
      <div v-else>
        <div class="text-body-3 h-full grow" :class="{ 'animate-pulse': pageState.isSummaryLoading }" data-cy="sites-list">
          <Disclosure v-for="siteRow in siteRows" :key="siteRow.id">
            <div
              :data-cy="'site-' + siteRow.name"
              class="focus-visible:ring/75 f flex w-full cursor-pointer justify-between gap-2 border-b bg-neutral-10 px-6 py-3 text-left font-medium hover:bg-neutral-20 focus:outline-none focus-visible:ring-blue-40"
              :class="{
                'border-divider-light': !isSiteSelected(siteRow.site.id),
                'border-divider-extralight': isSiteSelected(siteRow.site.id),
              }"
              @click="toggleSite(siteRow.site)">
              <DisclosureButton class="flex flex-auto">
                <div class="w-full">
                  <div class="w-full">
                    <div class="text-subheading-3 mb-1 max-w-[230px] truncate text-left">
                      {{ siteRow.name }} - {{ siteRow.site.address.city }}, {{ siteRow.site.address.state }}
                    </div>
                  </div>
                  <ul class="text-body-3 flex w-full gap-1.5 text-left">
                    <span data-cy="site-total-energy">{{ siteRow.energySumMwh.toLocaleString() }} MWh</span>
                    |
                    <span class="subtext inline-block">{{ siteRow.carbonSumTonnesCo2e.toLocaleString() }} tonnes ({{ siteRow.carbonRatio }})</span>
                  </ul>
                </div>
              </DisclosureButton>
              <button class="flex-0 -m-2 self-baseline p-2" data-cy="button-site-edit" @click="(event) => showSiteDialog(event, siteRow.site)">
                <Icon icon="wc-ic:edit-line" class="-mr-2 mt-0.5 size-6" />
              </button>
            </div>
            <div v-show="isSiteSelected(siteRow.site.id)">
              <DisclosurePanel static class="border-b border-divider-light px-6">
                <div :data-cy="'site-panel-' + siteRow.name">
                  <ul class="divide-y divide-divider-extralight" data-cy="asset-list">
                    <li
                      v-for="assetRow in siteRow.rows"
                      :key="assetRow.sourceId"
                      :data-cy="'asset-' + assetRow.name"
                      class="flex cursor-pointer items-start justify-between gap-2 py-3"
                      @click="toggleAsset(assetRow.sourceId)">
                      <div
                        data-cy="asset-checkmark"
                        class="mt-px flex shrink-0 items-center justify-center"
                        role="checkbox"
                        :aria-checked="isAssetSelected(assetRow.sourceId)">
                        <Icon
                          :icon="isAssetSelected(assetRow.sourceId) ? 'wc-carbon:checkbox-checked-filled' : 'wc-carbon:checkbox'"
                          class="size-[18px]" />
                      </div>
                      <div class="w-full">
                        <div class="text-subheading-3 flex items-center">
                          {{ assetRow.name }}
                        </div>
                        <div class="w-full text-left">
                          <span class="subtext inline-block text-xs">{{ assetRow.energySumMwh.toLocaleString() }} MWh</span>
                          |
                          <span class="subtext inline-block text-xs"
                            >{{ assetRow.carbonSumTonnesCo2e.toLocaleString() }} tonnes ({{ assetRow.carbonRatio }})
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </DisclosurePanel>
            </div>
          </Disclosure>
        </div>
      </div>
    </div>
    <div class="bottom-0 flex w-full gap-2 border-t border-sagetone p-6">
      <WcButton
        text="Cancel Changes"
        size="small"
        variant="secondary"
        :is-disabled="!hasPendingChanges"
        class="grow"
        @click="resetPendingSelections()" />
      <WcButton text="Update Data" size="small" :is-disabled="!hasPendingChanges" class="grow" @click="applyPendingFilters()" />
    </div>
  </aside>
  <WcSiteEditDialog v-model:is-open="isSiteDialogOpen" :site="activeSite" />
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel, Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue"
import { PropType, ref, watch, reactive, computed, Ref } from "vue"
import { Icon } from "@iconify/vue"
import { Site } from "@common/models/site"
import { SiteAsset } from "@common/models/siteAsset"
import { Account } from "@common/models/models"
import { LoadshapeSummaryQueryRequest } from "@common/models/loadshapeQueryRequest"
import { LoadshapeSummary } from "@common/models/loadshape"
import { WcButton } from "@/components/button"
import { SideNavFilters, SideNavRow } from "@/components/layout/layout.model"
import ChevronIcon from "@/components/icon/ChevronIcon.vue"
import WcSiteEditDialog from "@/modules/sites/WcSiteEditDialog.vue"
import { addMonths, endOfMonth, endOfYear, startOfMonth, startOfYear } from "date-fns"
import { gModelDict } from "@common/global"
import VueFlatPickr from "vue-flatpickr-component"
import { useQueryService } from "@/services/service-container"
import { useAuthService } from "@/services/service-container"
import { useSitesStore } from "@/store/sites"
import { useMainStore } from "@/store"

const props = defineProps({
  selectedSiteIds: { type: Array as PropType<Array<number>>, default: new Array<Site>(), required: false },
  selectedAssetIds: { type: Array as PropType<Array<number>>, default: new Array<Site>(), required: true },
  applyButtonIsDisabled: { type: Boolean, default: false, required: false },
})
const emit = defineEmits(["update:selectedSiteIds", "update:selectedAssetIds", "update"])

const sitesStore = useSitesStore()
const store = useMainStore()
const authService = useAuthService()

const siteRows = ref(new Array<any>())
const activeSite = ref<Site>(new Site())
const isSiteDialogOpen = ref<boolean>(false)
const queryService = useQueryService()
const pageState = reactive({
  isSummaryLoading: false,
})

const pendingSelectedSiteIds = ref<Array<number>>([])
const pendingSelectedAssetIds = ref<Array<number>>([])

const endDate = ref(endOfMonth(addMonths(new Date(), -1)))
const startDate = ref(startOfMonth(addMonths(new Date(), -12)))

const datePickerConfig = {
  altFormat: "M j, Y",
  altInput: true,
  dateFormat: "Z",
}

const setDate = (key: dateFilterKey) => {
  selectedDateFilter.value = key

  const dates = dateFilters[key]
  startDate.value = dates.startDate
  endDate.value = dates.endDate
}

const pendingFilters = computed<SideNavFilters>(() => {
  const _options = new SideNavFilters()
  _options.startDate = new Date(startDate.value)
  _options.endDate = new Date(endDate.value)

  return _options
})

const currentFilters = ref<SideNavFilters>(pendingFilters.value)

const hasPendingChanges = computed(() => {
  return [...pendingSelectedAssetIds.value].sort().join(",") !== [...props.selectedAssetIds].sort().join(",") || haveFiltersChanged.value
})

const haveFiltersChanged = computed(() => {
  let hasChanged = false
  Object.entries(pendingFilters.value).forEach(([key, value]) => {
    if (Object.hasOwn(currentFilters.value, key)) {
      hasChanged = value !== currentFilters.value[key]
    }

    if (hasChanged) {
      return
    }
  })

  return hasChanged
})

const selectedAccount = computed(() => store.accounts.find((account) => account.id === store.auth.user?.account_id) as Account)

const switchAccount = async (account: Account) => {
  await authService.switchAccount(account.id)
}
const isSiteSelected = (siteId: number) => pendingSelectedSiteIds.value.includes(siteId)
const isAssetSelected = (assetId: number) => pendingSelectedAssetIds.value.includes(assetId)
const isAllSelected = ref<boolean>(false)

const resetPendingSelections = () => {
  pendingSelectedSiteIds.value = [...props.selectedSiteIds]
  pendingSelectedAssetIds.value = [...props.selectedAssetIds]
  startDate.value = currentFilters.value.startDate
  endDate.value = currentFilters.value.endDate
}

const selectedDateFilter: Ref<string> = ref("last12Months")
const currentYear = startOfYear(new Date())
const lastYear = addMonths(currentYear, -1)
const lastYear2 = addMonths(currentYear, -13)
// TODO: replace this Union type with an enum so we can iterate
type dateFilterKey = "last12Months" | "lastYear" | "lastYear2"
const dateFilters: { [key in dateFilterKey]: any } = reactive({
  lastYear2: {
    label: lastYear2.getFullYear().toString(),
    startDate: startOfYear(lastYear2),
    endDate: endOfYear(lastYear2),
    previous: null,
  },
  lastYear: {
    label: lastYear.getFullYear().toString(),
    startDate: startOfYear(lastYear),
    endDate: endOfYear(lastYear),
    previous: "lastYear2",
  },
  last12Months: {
    label: "Last 12 months",
    startDate: new Date(startDate.value),
    endDate: new Date(endDate.value),
    previous: "lastYear",
  },
})

const showSiteDialog = (event: Event, site: Site = new Site(), showDialog = true) => {
  event.stopPropagation()
  activeSite.value = site
  isSiteDialogOpen.value = showDialog
}

const toggleSite = (site: Site) => {
  const index = pendingSelectedSiteIds.value.indexOf(site.id)
  if (index > -1) {
    pendingSelectedSiteIds.value.splice(index, 1)
    site.assets
      .map((a: SiteAsset) => a.id)
      .forEach((id: number) => {
        unselectAsset(id)
      })
  } else {
    pendingSelectedSiteIds.value.push(site.id)
  }
}

const unselectAsset = (assetId: number) => {
  toggleAsset(assetId, true)
}

const toggleAsset = (assetId: number, doRemove = false) => {
  const index = pendingSelectedAssetIds.value.indexOf(assetId)
  if (index > -1 || doRemove) {
    pendingSelectedAssetIds.value.splice(index, 1)
  } else {
    pendingSelectedAssetIds.value.push(assetId)
  }
}

const selectAll = () => {
  sitesStore.sites.forEach((site: Site) => {
    if (site.assets && !pendingSelectedSiteIds.value.includes(site.id)) {
      pendingSelectedSiteIds.value.push(site.id)
      site.assets.forEach((asset: SiteAsset) => {
        pendingSelectedAssetIds.value.push(asset.id)
      })
    }
  })
  isAllSelected.value = true
}

const unselectAll = () => {
  pendingSelectedSiteIds.value = [...props.selectedSiteIds]
  pendingSelectedAssetIds.value = [...props.selectedAssetIds]
  isAllSelected.value = false
}

const applyPendingFilters = async () => {
  const selectedSiteIds = pendingSelectedSiteIds.value
  const selectedAssetIds = pendingSelectedAssetIds.value
  if (haveFiltersChanged.value) {
    currentFilters.value = Object.assign({}, pendingFilters.value)
    await updateSummaryData()
  }

  emit("update:selectedSiteIds", [...selectedSiteIds])
  emit("update:selectedAssetIds", [...selectedAssetIds])
  emit("update", [...selectedAssetIds], currentFilters.value)
}

const createSummaryRows = (rows: Array<SideNavRow>, loadshapeSummaries: Array<LoadshapeSummary>) => {
  rows.forEach((row: SideNavRow) => {
    row.rows.forEach((assetRow: SideNavRow, index) => {
      if (Object.hasOwn(gModelDict, assetRow.type)) {
        row.rows[index].icon = gModelDict[assetRow.type].icon
      }
      const summary = loadshapeSummaries.find((s: LoadshapeSummary) => s.sourceId === assetRow.sourceId)
      if (summary) {
        row.rows[index] = SideNavRow.fromLoadshapeSummary(assetRow, summary)
        row.carbonSumTonnesCo2e += row.rows[index].carbonSumTonnesCo2e
        row.energySumMwh += row.rows[index].energySumMwh
      }
    })
    row.carbonRatio = row.energySumMwh !== 0 ? Number((row.carbonSumTonnesCo2e / row.energySumMwh).toFixed(2)) : 0
  })

  return rows
}
const updateSummaryData = async () => {
  pageState.isSummaryLoading = true
  siteRows.value = sitesStore.sites.map(SideNavRow.fromSite)
  const request = new LoadshapeSummaryQueryRequest(sitesStore.assetIds, currentFilters.value.startDate, currentFilters.value.endDate)

  const loadshapeSummaries = await queryService.listLoadshapeSummaries(request)
  if (loadshapeSummaries.length === 0) {
    return
  }

  siteRows.value = createSummaryRows(siteRows.value, loadshapeSummaries)

  pageState.isSummaryLoading = false
}

watch(
  () => sitesStore.sites,
  async () => {
    await updateSummaryData()
  },
  { deep: true, immediate: true }
)
</script>
