<template>
  <div id="asset-agreement">
    <p class="text-body-2">
      By clicking “SUBMIT ATTESTATION” at the bottom of this page, you warrant that you have been given and have received and accepted authority to
      sign, execute, and agree to this Attestation on behalf of the individual or organization (the “<strong>Participant</strong>”) proposing to
      register an account with and one or more Qualifying Assets on WEATS. You further represent and warrant that you have been expressly given and
      received and accepted authority to enter into a binding agreement on behalf of the Participant in respect of the matters stated in this
      Attestation.
    </p>
    <p class="text-body-2">
      In particular, you acknowledge and agree on behalf of the Participant that, by clicking “SUBMIT ATTESTATION” at the bottom of this page and
      registering an account with and one or more Qualifying Assets on WEATS:
    </p>
    <ul class="list-disc">
      <li>
        <p class="text-body-2">
          WattCarbon Inc. (“<strong>WattCarbon</strong>”) does not determine who has legal title to any Qualifying Asset registered on WEATS or any
          EAC generated by such Qualifying Asset. The information you provide in connection with this Attestation is intended solely to inform
          WattCarbon as to whether the owner of one or more Qualifying Assets and/or the exclusive right to all EACs generated by such asset(s), as
          applicable, has assigned or desires to assign such rights to another legal entity;
        </p>
      </li>
      <li>
        <p class="text-body-2">
          WattCarbon will rely on the information you provide in connection with this Attestation as governing its relationship with you and the
          applicable Qualifying Asset(s) and you have a duty to immediately notify WattCarbon in writing if or when any such information ceases to be
          truthful, accurate, or complete;
        </p>
      </li>
      <li>
        <p class="text-body-2">
          to the best of your knowledge, the right to register such Qualifying Asset(s) on WEATS and/or the exclusive right to any EACs generated by
          such asset(s), as applicable, have not been assigned other than as disclosed by you in connection with this Attestation;
        </p>
      </li>
      <li>
        <p class="text-body-2">
          to the best of your knowledge, such Qualifying Asset(s) and the environmental attributes associated with such asset(s) are not registered on
          any registry or other platform designed to track and issue certificates evidencing environmental attributes; and
        </p>
      </li>
      <li>
        <p class="text-body-2">
          unless expressly stated otherwise, you will be responsible for paying all fees applicable to the maintenance of such Qualifying Asset(s) on
          WEATS and the management of EACs generated by such asset(s).
        </p>
      </li>
    </ul>
    <p class="text-body-2">
      To the extent that you have contracted with the owner(s) of one or more Qualifying Assets for the right to register such asset(s) on WEATS
      and/or the exclusive right to all EACs generated by such asset(s), you further acknowledge and agree that, in order for the registration of such
      asset(s) on WEATS to be valid, all owners must be disclosed to WattCarbon during registration.
    </p>
    <p class="text-body-2">
      Nothing in this Attestation shall be construed to create or give rise to any liability on the part of WattCarbon. This Attestation shall not be
      construed to modify any terms or conditions of the WattCarbon Terms and Conditions of Use ( the “<strong>Terms</strong>”) and, in the event of
      conflict between this Attestation and the Terms, the Terms shall control.
    </p>
  </div>
</template>

<style lang="scss" scoped>
#asset-agreement {
  h2 {
    @apply mt-2;
  }
  p {
    margin-top: 0.5rem;
  }
  ul {
    padding-left: 2.7em;
  }
  ul li {
    padding-left: 0.5em;
    margin-top: 0.25rem;
  }
}
</style>
