<template>
  <template v-for="field in form.fields" :key="field.name">
    <template v-if="hide.includes(`${prefix}${field.name}`)">
      <input v-model="model[field.name]" type="hidden" :name="`${prefix}${field.name}`" />
    </template>
    <template v-else-if="field.type === 'form'">
      <fieldset :id="`${prefix}${field.name}`" :name="`${prefix}${field.name}`" class="flex flex-col gap-y-4">
        <legend>{{ field.title }}</legend>
        <WcOpenApiFormFields v-model="model[field.name]" :form="field" :prefix="`${prefix}${field.name}.`" :hide="hide" />
      </fieldset>
    </template>
    <template v-else-if="field.type === 'select'">
      <div class="form-field-select">
        <label>
          {{ field.title }}
          <select :id="`${prefix}${field.name}`" v-model="model[field.name]" :name="`${prefix}${field.name}`" :required="field.required">
            <option value="" :disabled="field.required"></option>
            <option v-for="option in field.options" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>
        </label>
      </div>
    </template>
    <template v-else-if="field.type === 'checkbox'">
      <div class="form-field-checkbox">
        <label>
          <input :id="`${prefix}${field.name}`" v-model="model[field.name]" type="checkbox" :name="`${prefix}${field.name}`" />
          {{ field.title }}
        </label>
      </div>
    </template>
    <template v-else>
      <div :class="`form-field-${field.type}`">
        <label>
          {{ field.title }}
          <input
            :id="`${prefix}${field.name}`"
            v-model="model[field.name]"
            :type="field.type"
            :name="`${prefix}${field.name}`"
            :min="field.min"
            :max="field.max"
            :minlength="field.minLength"
            :maxlength="field.maxLength"
            :inputmode="field.inputmode"
            :pattern="field.pattern"
            :placeholder="field.placeholder"
            :required="field.required" />
        </label>
      </div>
    </template>
  </template>
</template>

<script lang="ts" setup>
import { FormComponent } from "./WcOpenApiForm.utils"

const model = defineModel<any>({ default: {} })
const {
  form,
  prefix = "",
  hide = [],
} = defineProps<{
  form: FormComponent
  prefix?: string
  hide?: string[]
}>()
</script>

<style lang="scss" scoped>
fieldset {
  @apply border border-neutral-50 rounded p-4;
}

.form-field-checkbox label {
  @apply flex-row gap-2;

  input {
    @apply mt-1;
  }
}

.form-field-select select {
  @apply w-full;
}
</style>
