import { ref, provide, inject, type InjectionKey } from "vue"

export interface ConfirmOptions {
  title?: string
  message?: string
  confirmText?: string
  cancelText?: string
  icon?: string
}

interface ConfirmAPI {
  confirm: (options?: ConfirmOptions) => Promise<boolean>
}

const ConfirmContextKey = Symbol() as InjectionKey<ConfirmAPI>

// This is used ONCE at the app root level
export function provideConfirm() {
  const isOpen = ref(false)
  const options = ref<ConfirmOptions>({})
  let promiseResolverToCallOnConfirmCancel: ((value: boolean) => void) | null = null

  // Components call this to show the confirm modal and get back a promise that
  // resolves when the user confirms or dismisses the prompt
  const confirm = (confirmOptions: ConfirmOptions = {}) => {
    options.value = {
      title: "Confirm",
      message: "Are you sure you want to proceed?",
      confirmText: "Confirm",
      cancelText: "Cancel",
      icon: "wc-ic:dangerous-outlined",
      ...confirmOptions,
    }
    isOpen.value = true

    return new Promise<boolean>((resolve) => {
      promiseResolverToCallOnConfirmCancel = resolve
    })
  }

  // Called when user clicks Confirm
  const handleConfirm = () => {
    isOpen.value = false
    if (promiseResolverToCallOnConfirmCancel) {
      promiseResolverToCallOnConfirmCancel?.(true)
    }
  }

  // Called when user clicks Cancel or closes modal
  const handleCancel = () => {
    isOpen.value = false
    if (promiseResolverToCallOnConfirmCancel) {
      promiseResolverToCallOnConfirmCancel?.(false)
    }
  }

  provide(ConfirmContextKey, { confirm })

  // Return values needed by the WcConfirm component itself
  return {
    isOpen,
    options,
    handleConfirm,
    handleCancel,
  }
}

// Components call this to get access to the confirm function
export function useConfirm() {
  const api = inject(ConfirmContextKey)
  if (!api) throw new Error("useConfirm must be used within a ConfirmContext")
  return api
}
