import type { AssetUploadError, AssetUploadResult } from "@/modules/asset/asset.service"

export const formatUploadError = (error: AssetUploadError) => {
  if (error.type.startsWith("type_error.")) {
    return `Invalid data or formatting: "${error.loc.join(".")}"`
  }
  if (error.type.startsWith("value_error.")) {
    return `Missing data in required field: "${error.loc.join(".")}"`
  }
  if (error.loc.length > 0) {
    return `${error.msg}: "${error.loc.join(".")}"`
  }
  if (error.type === "creation_error" && error.msg === "customId already in use") {
    return "This Internal ID is already in use on another asset and must be unique"
  }
  return error.msg
}

// Merge the validation results with the uploaded data
export const formatValidationResultsForReview = (results: AssetUploadResult[], csvRows: any[]) => {
  return results.map(({ errors }, rowNumber) => {
    const { errors: _errors, ...row } = csvRows[rowNumber]
    const errorMessages = (errors ?? []).map((error) => formatUploadError(error))
    const warnings = [] as string[]
    return {
      line: rowNumber + 2,
      errors: errorMessages,
      warnings: [],
      issues: `${errorMessages.join(",")}${warnings.join(",")}`,
      ...row,
    }
  })
}
