<template>
  <WcTable
    :data="assetsData"
    :columns="[
      { key: 'id', label: 'ID', align: 'left' },
      { key: 'status', label: 'Status', align: 'left' },
      { key: 'kind', label: 'Type', align: 'left' },
      { key: 'account', label: 'Account', align: 'left' },
      ...(showStoryColumn ? [{ key: 'story', label: 'Story', align: 'left' } as TableHeader] : []),
    ]"
    row-id-field="id"
    table-class="w-full text-body-2"
    tr-class="cursor-pointer even:bg-highlight-pastelazure hover:bg-highlight-frostblue transition-colors"
    th-class="text-left"
    td-class="px-3 py-2"
    :sortable="true">
    <template #account="{ row }">
      <div class="max-w-64">
        {{ row.account }}
      </div>
    </template>
    <template #status="{ row }">
      <div class="flex max-w-64">
        <AssetStatusIcon :status="row.status" class="mt-[3px]" />
        {{ row.statusSummary }}
      </div>
    </template>
    <template #kind="{ row }">
      <div class="max-w-64">
        {{ row.kind }}
      </div>
    </template>
    <template #story="{ row }">
      <div class="max-w-64 truncate">
        {{ row.story }}
      </div>
    </template>
  </WcTable>
</template>

<script setup lang="ts">
import { computed } from "vue"
import type { TableHeader } from "@/components/WcTable/WcTable"
import WcTable from "@/components/WcTable/WcTable.vue"
import type { Asset, AssetStatus } from "@common/models/asset"
import { ASSET_KIND, ASSET_STATUS } from "@common/models/asset"
import type { Story } from "@common/models/story"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import AssetStatusIcon from "@/modules/asset/components/AssetStatusIcon.vue"

type AssetRow = {
  account: string | number
  id: number
  kind: string
  status: AssetStatus
  statusSummary: string
  story: string
}

const adminAccountsStore = useAdminAccountsStore()

const props = defineProps<{
  assets: Asset[]
  showStoryColumn?: boolean
  stories: Story[]
}>()

const assetsData = computed<AssetRow[]>(() => {
  return props.assets.map((asset) => {
    const storyName = props.stories.find((story) => story.id === asset.storyId)?.name
    return {
      account: adminAccountsStore.getAccountById(asset.accountId)?.name ?? asset.accountId,
      id: asset.id,
      kind: ASSET_KIND[asset.kind],
      status: asset.status,
      statusSummary: ASSET_STATUS[asset.status].summary || asset.status,
      story: asset.storyId ? `${asset.storyId}: ${storyName}` : "",
    }
  })
})
</script>
