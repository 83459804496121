<template>
  <section class="w-full">
    <template v-if="hasAvailableDateRange">
      <p class="text-body-2">Show values for the selected date range, aggregated over a given time period:</p>
      <div class="mb-5 mt-8 flex items-start justify-between pb-2">
        <div class="flex grow flex-wrap items-center gap-3">
          <div class="basis-[180px]">
            <label class="text-body-3 pb-1 text-black" for="interval">Time period</label>
            <WcDropdown id="interval" v-model="interval" name="interval" :options="intervalOptions" required size="small"> </WcDropdown>
          </div>
          <div class="shrink-0 basis-40" :class="{ 'basis-56': (dateRange || [])[1] != null }">
            <label class="text-body-3 pb-1 text-black" for="dateRange">Date range</label>
            <WcCalendarRange id="dateRange" v-model="dateRange" name="dateRange" size="small">
              <template #presetPanel="{ selectPreset, isPresetActive }">
                <div class="grid grid-cols-3 gap-4">
                  <WcCTA
                    v-for="(presetDateRange, label) in presets"
                    :key="label"
                    :text="label"
                    variant="text"
                    size="large"
                    :weight="isPresetActive(presetDateRange[0], presetDateRange[1]) ? 'heavy' : 'normal'"
                    @click="() => selectPreset(presetDateRange)" />
                </div>
              </template>
            </WcCalendarRange>
          </div>
          <div class="basis-[200px]">
            <label class="text-body-3 pb-1 text-black" for="source">EAC source</label>
            <WcDropdown id="source" v-model="source" name="source" :options="sourceOptions" required size="small"> </WcDropdown>
          </div>
        </div>
        <WcLoadingSpinner v-if="isLoading" class="mt-0.5 h-9 w-16" />
      </div>
      <AssetEnergyOverTime :data="assetLoadshapeRows" height="500" :interval="interval?.value" :has-electricity-rows="hasElectricityRows" />
    </template>
    <EmptyState v-else title="No EACs produced"> Once your asset(s) start producing EACs, those will be shown here. </EmptyState>
  </section>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue"
import { addDays, differenceInDays } from "date-fns"
import { WcCTA } from "@/components/button"
import type { SelectOption } from "@/components/input"
import { WcCalendarRange, WcDropdown } from "@/components/input"
import EmptyState from "@/components/ui/EmptyState.vue"
import WcLoadingSpinner from "@/components/WcLoadingSpinner.vue"
import type { AssetGroupSummary, AssetSummary, LoadshapeRequestOptions } from "@/modules/asset/asset.service"
import type { APIAssetLoadshapeRow, LoadshapeEacSource, LoadshapeInterval } from "@/modules/asset/assetLoadshape.model"
import {
  AssetLoadshapeRow,
  convertToDaily,
  convertToMonthly,
  convertToUTCTimeOfDay,
  dateRangePresetGivenAvailableDataRange,
  LOADSHAPE_EAC_SOURCE,
  LOADSHAPE_INTERVAL,
  makePresetDateRanges,
} from "@/modules/asset/assetLoadshape.model"
import { ELECTRICITY_UNITS } from "@/services/api/registry.model"
import AssetEnergyOverTime from "./AssetEnergyOverTime.vue"

const presets = makePresetDateRanges()

const sourceOptions = Object.entries(LOADSHAPE_EAC_SOURCE).map(([value, label]) => ({ label, value }) as SelectOption<LoadshapeEacSource>)
const intervalOptions = Object.entries(LOADSHAPE_INTERVAL).map(([value, label]) => ({ label, value }) as SelectOption<LoadshapeInterval>)

const props = defineProps<{ isLoading: boolean; rows: APIAssetLoadshapeRow[]; summary?: AssetSummary | AssetGroupSummary }>()

const parseAvailableDateRange = (summary?: AssetSummary | AssetGroupSummary) => {
  const sumsWithDateRange = (summary?.eacs?.sum || []).filter((eac) => eac.lower && eac.upper) as { lower: string; upper: string }[]
  if (sumsWithDateRange.length === 0) {
    return {
      lower: null,
      upper: null,
    }
  }
  return {
    lower: sumsWithDateRange.map(({ lower }) => lower).reduce((a, b) => (a < b ? a : b)),
    upper: sumsWithDateRange.map(({ upper }) => upper).reduce((a, b) => (a > b ? a : b)),
  }
}

const availableDataRange = parseAvailableDateRange(props.summary)

const defaultDateRange =
  availableDataRange.lower && availableDataRange.upper ? dateRangePresetGivenAvailableDataRange(availableDataRange) : presets["Last 12 Months"]

const hasAvailableDateRange = computed(() => availableDataRange.lower && availableDataRange.upper)
const dateRange = ref<Date[] | null | undefined>(defaultDateRange)
const interval = ref<SelectOption<LoadshapeInterval> | null>(intervalOptions[0])
const source = ref<SelectOption<LoadshapeEacSource>>(sourceOptions[0])

const emit = defineEmits<{
  "load-rows": [options: LoadshapeRequestOptions]
}>()

watch(
  [dateRange, source],
  () => {
    const startDate = (dateRange.value ?? [])[0] ?? defaultDateRange[0]
    let endDate = (dateRange.value ?? [])[1] ?? defaultDateRange[0]

    // Ensure end date is not before start date
    if (startDate > endDate) {
      endDate = startDate
    }

    // Ensure the date range is not greater than the API limit of 366 days
    if (differenceInDays(endDate, startDate) > 366) {
      endDate = addDays(startDate, 366)
      dateRange.value = [startDate, endDate]
    }

    const options = {
      startDate: startDate.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0],
      source: source.value.value,
    } as LoadshapeRequestOptions
    emit("load-rows", options)
  },
  { immediate: true }
)

const assetLoadshapeRows = computed(() => {
  if (interval.value?.value === "monthly") {
    return convertToMonthly(props.rows).map((row) => new AssetLoadshapeRow(row))
  } else if (interval.value?.value === "daily") {
    return convertToDaily(props.rows).map((row) => new AssetLoadshapeRow(row))
  } else if (interval.value?.value === "timeOfDay") {
    return convertToUTCTimeOfDay(props.rows).map((row) => new AssetLoadshapeRow(row))
  }
  return props.rows.map((row) => new AssetLoadshapeRow(row))
})

const hasElectricityRows = computed(() => {
  return props.rows.some((row) => row.values.some((v) => ELECTRICITY_UNITS.indexOf(v.units) !== -1))
})
</script>
