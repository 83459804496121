import ApiFetcher from "@/services/api-fetcher"
import { Supplier, HighlightProject } from "@common/models/supplier"

export default class SupplierService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  getSupplier = async (id: number): Promise<Supplier> => {
    return await this.fetcher.httpGet<Supplier>(`/suppliers/${id}`, {})
  }

  listSuppliers = async (): Promise<Array<Supplier>> => {
    const endpoint = "/suppliers"
    try {
      const suppliers = await this.fetcher.httpGet<Array<Supplier>>(endpoint, {})

      return new Promise<Array<Supplier>>((resolve) => {
        resolve(suppliers)
      })
    } catch {
      return new Promise<Array<Supplier>>((resolve, reject) => {
        reject("Unable to view suppliers")
      })
    }
  }

  listSuppliersAdmin = async (): Promise<Array<Supplier>> => {
    const endpoint = "/suppliers/admin"
    try {
      const suppliers = await this.fetcher.httpGet<Array<Supplier>>(endpoint, {})

      return new Promise<Array<Supplier>>((resolve) => {
        resolve(suppliers)
      })
    } catch {
      return new Promise<Array<Supplier>>((resolve, reject) => {
        reject(new Error("Unable to view suppliers"))
      })
    }
  }

  addSupplier = async (supplierRequest: Supplier) => {
    const endpoint = "/suppliers"
    return await this.fetcher.httpPost<Supplier>(endpoint, supplierRequest)
  }

  updateSupplier = async (supplierRequest: Supplier) => {
    const endpoint = `/suppliers/${supplierRequest.accountId}`
    return await this.fetcher.httpPut<Supplier>(endpoint, supplierRequest)
  }

  addImage = async (supplierId: number, fileData: File, description: string): Promise<Supplier> => {
    return await this.fetcher.httpUpload<Supplier>(`/suppliers/${supplierId}/images`, { image: fileData, description: description }, fileData)
  }

  updateImage = async (supplierId: number, imageId: number, description: string): Promise<Supplier> => {
    return await this.fetcher.httpPut<Supplier>(`/suppliers/${supplierId}/images/${imageId}`, { description: description })
  }

  removeImage = async (supplierId: number, imageId: number) => {
    await this.fetcher.httpDelete(`/suppliers/${supplierId}/images/${imageId}`)
    return await this.getSupplier(supplierId)
  }

  addProject = async (supplierId: number, project: HighlightProject): Promise<Supplier> => {
    return await this.fetcher.httpPost<Supplier>(`/suppliers/${supplierId}/highlight-projects`, project)
  }

  updateProject = async (supplierId: number, project: HighlightProject): Promise<Supplier> => {
    return await this.fetcher.httpPut<Supplier>(`/suppliers/${supplierId}/highlight-projects/${project.id}`, project)
  }

  removeProject = async (supplierId: number, projectId: number): Promise<Supplier> => {
    return await this.fetcher.httpDelete<Supplier>(`/suppliers/${supplierId}/highlight-projects/${projectId}`)
  }
}
