<template>
  <table class="w-full">
    <thead>
      <tr class="border-b border-sagetone">
        <th class="text-left">Time</th>
        <th class="text-left">Action</th>
        <th class="text-left">Recipient</th>
        <th class="text-left">EACs</th>
      </tr>
    </thead>
    <tbody class="divide-y divide-sagetone">
      <tr v-if="transactions.length == 0">
        <td colspan="3" class="py-2 text-left">
          <p v-if="!isLoading">Nada</p>
          <Icon v-else icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
        </td>
      </tr>
      <tr v-for="transaction in transactions" :key="transaction.id">
        <td class="py-2">
          <time :datetime="transaction.createdTime">{{ formatDate(transaction.createdTime) }}</time>
        </td>
        <td>{{ formatDescription(transaction) }}</td>
        <td>{{ formatAccount(transaction) }}</td>
        <td>{{ formatAmount(transaction) }}</td>
      </tr>
    </tbody>
  </table>

  <PaginationButtons v-if="pageInfo" :page-info="pageInfo" :is-disabled="isLoading" @load-items="loadTransactions" />
</template>

<script setup lang="ts">
import type { Asset } from "@common/models/asset"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import { ref, watch } from "vue"
import type { PageInfo } from "@/services/base-fetcher"
import { Icon } from "@iconify/vue"
import { useTransactionService } from "@/services/service-container"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"
import { CARBON_UNITS, ELECTRICITY_UNITS, PublicTransaction, PublicTransactionDetails } from "@/services/api/transaction.service"
import { parseUTCTimestamp } from "@/utils/parseUTCTimestamp"

const props = defineProps<{ asset: Asset }>()

// This emit is not implemented, but defined here in order to follow the
// interface between AdminAssetView and its tabs
defineEmits(["on-asset-update-state"])

const transactionService = useTransactionService()

const isLoading = ref(false)
const pageInfo = ref<PageInfo>()
const transactions = ref<PublicTransaction[]>([])

const loadTransactions = async (url?: string) => {
  try {
    isLoading.value = true

    const result = await transactionService.listTransactionsAdmin({ deviceId: props.asset.id, url })
    transactions.value = result.data
    pageInfo.value = result.pageInfo
  } finally {
    isLoading.value = false
  }
}

watch(
  () => props.asset,
  async () => {
    loadTransactions()
  },
  { immediate: true }
)

const formatter = new Intl.DateTimeFormat("en", { dateStyle: "short", timeStyle: "medium" })
const formatDate = (date: string) => {
  return formatter.format(parseUTCTimestamp(date))
}

const formatDescription = (transaction: PublicTransaction) => {
  for (const detail of transaction.details) {
    switch (detail.kind) {
      case "minting":
        return `EACs minted`
      case "allocation":
        return "EACs allocated"
      case "registration":
        return "Asset registered"
      default:
        return detail.kind
    }
  }
}

const formatAccount = (transaction: PublicTransaction) => {
  for (const detail of transaction.details) {
    if (detail.amount > 0) {
      return detail.account.name
    }
  }

  return "–"
}

const formatEacQuantity = (amountDetailEntry: PublicTransactionDetails) => {
  if (![...ELECTRICITY_UNITS, ...CARBON_UNITS].includes(amountDetailEntry.units)) {
    return ""
  }
  const measurementUnit = ELECTRICITY_UNITS.includes(amountDetailEntry.units)
    ? EacMeasurementParameter.Electricity
    : EacMeasurementParameter.GhgEmissions
  return getFormattedEacQuantity(Math.abs(amountDetailEntry.amount || 0), measurementUnit, 2, "never")
}

const formatAmount = (transaction: PublicTransaction) => {
  for (const detail of transaction.details) {
    if (detail.amount > 0) {
      return formatEacQuantity(detail)
    }
  }

  return "–"
}
</script>
