<template class="text-body-3">
  <table :class="tableClass">
    <thead class="border-b border-neutral-30">
      <tr>
        <th v-if="allowMultipleSelection" class="w-[30px] cursor-pointer px-3" @click.stop="handleToggleAllRowSelection">
          <input
            type="checkbox"
            :indeterminate="selectAllState === CheckboxState.indeterminate"
            :checked="selectAllState === CheckboxState.checked"
            class="cursor-pointer align-middle" />
        </th>
        <th
          v-for="column in columns"
          :key="column.key"
          :scope="getColumnScope(column)"
          class="text-subheading-3 relative px-3 py-2 text-left align-bottom"
          :class="getThClass(column)"
          @click="sortBy(column.key)">
          <div
            class="flex items-end"
            :class="column.align === 'left' ? 'justify-start' : column.align === 'right' ? 'justify-end text-right' : 'justify-center'">
            <div v-if="sortable && column.align === 'right'">
              <ChevronIcon
                :class="{
                  'rotate-180': sortOrder === 1,
                  visible: sortKey === column.key,
                  invisible: sortKey !== column.key,
                }"
                class="size-4 text-blue-70" />
            </div>
            {{ column.label }}
            <ToolTip
              v-if="column.tooltip != undefined"
              button-class="w-2.5 mx-2 !pt-1"
              :opener-color="darkMode ? 'primary-dark' : 'primary'"
              panel-class="!-top-1">
              <div>{{ column.tooltip }}</div>
            </ToolTip>
            <div v-if="sortable && column.align !== 'right'" class="w-0 shrink-0 basis-0">
              <ChevronIcon
                :class="{
                  'rotate-180': sortOrder === 1,
                  visible: sortKey === column.key,
                  invisible: sortKey !== column.key,
                }"
                class="size-4 text-blue-70" />
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="row in sortedData"
        :key="getRowId(row)"
        tabindex="0"
        :class="getRowClass(row)"
        @click="(event) => rowClicked(row, event)"
        @keydown="(event) => rowKeyDown(row, event)">
        <td v-if="allowMultipleSelection" class="w-[30px] px-3 text-center" @click.stop="() => handleToggleRowSelection(row)">
          <input v-model="selectedRowMapping[getRowId(row)]" class="!mb-[3px] cursor-pointer align-middle" type="checkbox" />
        </td>
        <td
          v-for="column in columns"
          :key="column.key"
          :class="[
            column.align === 'left' ? 'text-left' : column.align === 'right' ? 'text-right' : 'text-center',
            'group-[.selected-row]:font-bold',
            getTdClass(column),
          ]">
          <slot :name="column.key.replaceAll('.', '-')" :row="row" v-bind="_.get(row, column.key)">
            {{ _.get(row, column.key) }}
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue"
import _ from "lodash"
import ChevronIcon from "../icon/ChevronIcon.vue"
import ToolTip from "../ui/ToolTip.vue"
import type { TableHeader } from "./WcTable"
import { selectedRowIdsToMapping, selectedRowMappingToIds } from "./WcTable.utils"

const emit = defineEmits(["row-clicked", "rows-selected"])

type Row = Record<string, any>

interface Props {
  darkMode?: boolean
  data: Array<Row>
  columns: Array<TableHeader>
  rowIdField: string
  tableClass?: string
  thClass?: string
  trClass?: string | ((row: Row) => string)
  tdClass?: string
  sortable?: boolean
  defaultSortKey?: string
  selectedRowIds?: number[]
  allowMultipleSelection?: boolean
  selectOnRowClick?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  darkMode: false,
  sortable: true,
})

const sortKey = ref<string>(props.defaultSortKey ?? "")
const sortOrder = ref<number>(1)
const selectedRowMapping = ref<Record<number, boolean>>(selectedRowIdsToMapping(props.selectedRowIds))

// Sorting
enum SortingMethod {
  none = 0,
  number = 1,
  string = 2,
}

const sortedData = computed(() => {
  if (props.data.length < 2) return props.data

  // Use a custom column sort if defined by the consumer
  const sortedColumn = props.columns.find((column) => column.key === sortKey.value)
  if (sortedColumn?.customSort) {
    return sortedColumn.customSort({ data: props.data, isAscending: sortOrder.value === 1 })
  }

  // pre-determine sorting method using first two rows
  let sortingMethod: SortingMethod = 0
  const firstValue = _.get(props.data[0], sortKey.value)
  const secondValue = _.get(props.data[1], sortKey.value)
  if (!isNaN(Number(firstValue)) && !isNaN(Number(secondValue))) {
    sortingMethod = SortingMethod.number
  } else {
    sortingMethod = SortingMethod.string
  }

  return props.data.slice().sort((a, b) => {
    const modifier = sortOrder.value
    const firstValue = _.get(a, sortKey.value)
    const secondValue = _.get(b, sortKey.value)
    let comparison
    if (sortingMethod === SortingMethod.number) {
      comparison = Number(firstValue) - Number(secondValue)
    } else if (sortingMethod === SortingMethod.string) {
      comparison = (firstValue ?? "").localeCompare(secondValue ?? "")
    } else {
      comparison = 0
    }
    return modifier * comparison
  })
})

const sortBy = (key: string) => {
  if (!props.sortable) return
  sortOrder.value = key === sortKey.value ? -sortOrder.value : 1
  sortKey.value = key
}

// Getters
const getRowId = (row: Row) => {
  return _.get(row, props.rowIdField)
}

const getColumnScope = (column: TableHeader) => {
  return column.key === "actions" ? "col" : "col"
}

const getRowClass = (row: Row) => {
  const classes = ["group"]

  if (typeof props.trClass === "function") {
    classes.push(props.trClass(row))
  } else if (props.trClass) {
    classes.push(props.trClass)
  }

  if (selectedRowMapping.value[getRowId(row)]) {
    classes.push("selected-row")
  }

  return classes.join(" ")
}

const getTdClass = (column: TableHeader) => {
  const classes = []
  if (props.tdClass) classes.push(props.tdClass)
  if (column.tdClass) classes.push(column.tdClass)
  return classes.join(" ")
}

const getThClass = (column: TableHeader) => {
  const classes = []
  if (props.thClass) classes.push(props.thClass)
  if (column.thClass) classes.push(column.thClass)
  if (props.sortable) classes.push("hover:bg-neutral-20 cursor-pointer select-none")
  return classes.join(" ")
}

// Row Selection
enum CheckboxState {
  "checked" = "checked",
  "indeterminate" = "indeterminate",
  "unchecked" = "unchecked",
}

watch(
  () => props.selectedRowIds,
  (newSelectedRowIds) => {
    selectedRowMapping.value = selectedRowIdsToMapping(newSelectedRowIds)
  }
)

const selectAllState = computed(() => {
  const selectedRowIDs = selectedRowMappingToIds(selectedRowMapping.value)
  if (selectedRowIDs.length === 0) {
    return CheckboxState.unchecked
  } else if (selectedRowIDs.length < props.data.length) {
    return CheckboxState.indeterminate
  } else {
    return CheckboxState.checked
  }
})

const handleToggleAllRowSelection = () => {
  if (selectAllState.value === CheckboxState.indeterminate || selectAllState.value === CheckboxState.unchecked) {
    const selectedRowIds = sortedData.value.map((row) => getRowId(row))
    selectedRowMapping.value = selectedRowIdsToMapping(selectedRowIds)
  } else {
    selectedRowMapping.value = {}
  }
  emitRowsSelected()
}

const handleToggleRowSelection = (row: Row) => {
  const id = getRowId(row)
  const previousIsChecked = selectedRowMapping.value[id] ?? false
  selectedRowMapping.value[id] = !previousIsChecked
  emitRowsSelected()
}

// Emits
const rowClicked = (row: Row, event: MouseEvent) => {
  if (props.allowMultipleSelection && props.selectOnRowClick) {
    handleToggleRowSelection(row)
  } else {
    emit("row-clicked", row, event)
  }
}

const rowKeyDown = (row: Row, event: KeyboardEvent) => {
  if (event.key === "Enter") {
    emit("row-clicked", row, event)
  }
}

const emitRowsSelected = () => {
  const selectedRowIDs = selectedRowMappingToIds(selectedRowMapping.value)
  emit("rows-selected", selectedRowIDs)
}
</script>

<style scoped lang="scss">
.table-fixed {
  th:last-of-type {
    width: auto !important;
  }
}
</style>
