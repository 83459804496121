// import useFeature from "./useFeature"

// These represent feature flags defined in posthog. Flags should be
// short-lived, existing only during development of a larger feature, or as
// part of an A/B test with a concrete outcome.

/* e.g.
// Tracking removal in WATT-2478 (Deadline: May 21)
// https://wattcarbon.atlassian.net/browse/WATT-2478
export const useMarketplaceStoriesFeature = () => useFeature("marketplace-stories", { valueInDev: true })
*/

import useFeature from "./useFeature"

// This is a semi-long-lived feature flag that we'll use to allowlist time
// series uploads to WC internal users and a limited set of external users.
// Re-evaluate June 1, 2025.
export const useAllowUploadAssetTimeSeries = () => useFeature("allow-upload-asset-timeseries", { valueInDev: true })
