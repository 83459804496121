import { defineStore } from "pinia"
import { ref } from "vue"
import { getEnvironment } from "@/environment"
import * as jsonPointer from "json-pointer"

export const useOpenApiStore = defineStore("openapi", () => {
  const spec = ref({})

  const loadSpec = async () => {
    const { API_BASE_URL } = getEnvironment()

    try {
      const response = await fetch(`${API_BASE_URL}/openapi.json`)
      spec.value = await response.json()
    } catch (error) {
      console.error("Failed to load OpenAPI spec", error)
    }
  }

  const dereferenceUri = (pointer: string): any => {
    const parsedPointer = jsonPointer.parse(pointer.slice(1))
    if (!jsonPointer.has(spec.value, parsedPointer)) {
      return null
    }

    const result = jsonPointer.get(spec.value, parsedPointer)
    if (result.$ref !== undefined) {
      const ref = dereferenceUri(result["$ref"])
      return { $id: result.$ref, ...ref, ...result }
    } else {
      return { $id: pointer, ...result }
    }
  }

  loadSpec()

  return {
    // state
    spec,
    // actions
    loadSpec,
    dereferenceUri,
  }
})

export type OpenApiStore = ReturnType<typeof useOpenApiStore>
