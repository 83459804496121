<template>
  <SelectButton v-model="selected" :options="props.options" option-label="label" :allow-empty="false" class="wc-segmented-button" :class="inputClass">
    <template #option="slotProps">
      <div class="flex items-center gap-2">
        <Icon
          v-if="slotProps.option.icon"
          :icon="slotProps.option.icon"
          aria-hidden="true"
          :class="{ 'size-4': size === 'small', 'size-5': size === 'large' || size === undefined }" />
        <template v-if="slotProps.option.label && !iconOnly">
          {{ slotProps.option.label }}
        </template>
      </div>
    </template>
  </SelectButton>
</template>

<script setup lang="ts" generic="T">
import { Icon } from "@iconify/vue"
import SelectButton from "primevue/selectbutton"
import type { InputSize, SelectOption } from "@/components/input"
import { useInputClass } from "@/components/input"
const props = defineProps<{
  iconOnly?: boolean
  options: SelectOption<T>[]
  size?: InputSize
}>()
const selected = defineModel<SelectOption<T> | null>("selected")
const inputClass = useInputClass(props)
</script>

<style lang="scss">
.wc-segmented-button {
  &.p-button-group {
    .p-button {
      @apply border border-sagetone text-black rounded;

      &:not(.p-highlight) {
        @apply hover:bg-sagetone-lightbackground/50;
      }
      &.p-highlight {
        @apply bg-sagetone-lightbackground text-black border-sagetone;
      }
      svg {
        @apply m-0.5;
      }
    }
    .p-button:first-of-type:not(:only-of-type) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
    .p-button:last-of-type:not(:only-of-type) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }
  }
}
</style>
