<template>
  <form class="flex flex-col space-y-4" @submit.prevent="handleSubmit">
    <WcOpenApiFormFields v-model="model" :form="form" :hide="hide" />

    <div v-if="errors.length > 0" class="border border-error bg-error-light p-2">
      <h5 class="text-subheading-large-bold mb-4">Errors</h5>
      <ul class="list-inside list-disc">
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
    </div>

    <WcButton type="submit" text="Save" :submit-disabled="submitDisabled" />
  </form>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { prepareForm } from "./WcOpenApiForm.utils"
import { useOpenApiStore } from "./openapi.state"
import WcOpenApiFormFields from "./WcOpenApiFormFields.vue"
import WcButton from "../button/WcButton.vue"

const emit = defineEmits<{
  submit: [model: any]
}>()
const model = defineModel<any>()
const {
  pointer,
  errors = [],
  hide = [],
  submitDisabled = false,
} = defineProps<{
  pointer: string
  errors?: string[]
  hide?: string[]
  submitDisabled?: boolean
}>()

const openApiStore = useOpenApiStore()

const form = computed(() => prepareForm(openApiStore, pointer))

const handleSubmit = () => {
  emit("submit", model.value)
}
</script>
