<template>
  <div v-if="!!account" class="min-h-[600px] rounded-md bg-white p-3 shadow-md">
    <div class="flex w-full flex-row justify-between">
      <div>
        <h1 class="text-heading-4 mb-4 max-w-3xl break-words">{{ account.name }}</h1>

        <table>
          <tr>
            <th scope="row">ID</th>
            <td>{{ account.id }}</td>
          </tr>
          <tr>
            <th scope="row">Type</th>
            <td>{{ account.type }}</td>
          </tr>
          <tr>
            <th scope="row">API Key</th>
            <td class="font-mono">{{ account.api_key }}</td>
          </tr>
          <tr>
            <th scope="row">Address</th>
            <td v-if="account.contact_info && account.contact_info.city">
              {{ account.contact_info.street_1 }} {{ account.contact_info.city }}, {{ account.contact_info.state_province }}
            </td>
            <td v-else></td>
          </tr>
          <tr>
            <th class="align-top">Attestation</th>
            <td>
              <Skeleton v-if="attestationLoading" width="50%" height="1em" />
              <template v-else-if="attestation">
                Signed as <span :title="attestation.registrationRights">{{ attestationRights }}</span> by <q>{{ attestation.signature }}</q> on
                <time :datetime="attestation.createdTime" :title="attestation.createdTime">{{ formatDate(attestation.createdTime) }}</time>
              </template>
              <template v-else> None </template>
            </td>
          </tr>
        </table>
      </div>

      <div class="flex w-1/3 justify-end p-2">
        <img :src="account.logo_url" class="max-h-[70px]" />
      </div>
    </div>

    <div class="mt-2 flex gap-2">
      <input
        ref="logoFileInputRef"
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        class="hidden"
        :disabled="adminAccountsStore.isSaving"
        @input="uploadLogo" />
      <WcButton
        text="Upload new image"
        icon="wc-carbon:upload"
        size="small"
        :is-disabled="adminAccountsStore.isSaving"
        @click="logoFileInputRef?.click()" />

      <WcButton
        text="Delete Account"
        color="alert"
        icon="wc-carbon:delete"
        size="small"
        :is-disabled="adminAccountsStore.isSaving"
        @click="deleteAccount()" />

      <WcButton
        text="Delete All Assets"
        color="alert"
        icon="wc-carbon:delete"
        size="small"
        :is-disabled="adminAccountsStore.isSaving"
        @click="deleteAccountAssets()" />
    </div>

    <nav class="my-4 flex flex-row gap-2 rounded-md bg-neutral-20 p-1">
      <router-link class="tab" :to="{ name: 'wc-admin-account-users' }">Users</router-link>
      <router-link class="tab" :to="{ name: 'wc-admin-account-orders' }">Orders</router-link>
      <router-link class="tab" :to="{ name: 'wc-admin-account-stakes' }">Stakes</router-link>
      <router-link class="tab" :to="{ name: 'wc-admin-account-assets' }">Assets</router-link>
      <router-link class="tab" :to="{ name: 'wc-admin-account-audit-summary' }">Audit Summary</router-link>
      <router-link class="tab" :to="{ name: 'wc-admin-account-config' }">Configuration</router-link>
      <router-link class="tab" :to="{ name: 'wc-admin-account-contact' }">Contact Info</router-link>
      <router-link v-if="account.type === 'organization'" class="tab" :to="{ name: 'wc-admin-account-supplier' }">Supplier Spotlight</router-link>
    </nav>

    <router-view></router-view>
  </div>
  <div v-else>
    <p>Account not found.</p>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue"
import { WcButton } from "@/components/button"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import { useAssetService } from "@/services/service-container"
import { Attestation, REGISTRATION_RIGHTS } from "@/modules/asset/asset.service"
import { useRouter } from "vue-router"
import Skeleton from "primevue/skeleton"

const router = useRouter()
const adminAccountsStore = useAdminAccountsStore()
const assetService = useAssetService()
const logoFileInputRef = ref<HTMLInputElement | null>(null)

const props = defineProps<{
  accountId: number
}>()

const account = computed(() => adminAccountsStore.getAccountById(props.accountId)!)

const attestationLoading = ref(true)
const attestation = ref<Attestation | null>(null)
const attestationRights = computed(() => {
  if (!attestation.value) {
    return null
  }

  for (const rights of REGISTRATION_RIGHTS) {
    if (rights.name === attestation.value.registrationRights) {
      return rights.short
    }
  }
  return "Unknown"
})
const formatter = new Intl.DateTimeFormat("en", { dateStyle: "short" })
const formatDate = (date: string) => formatter.format(new Date(date))

watchEffect(async () => {
  // Force a fetch from the backend when viewing an individual user, for two
  // reasons:
  // 1. The users list is only populated by the backend when fetching an
  // individual account (to keep the cost of the account list down).
  // 2. To ensure we have the latest data in case someone else is editing
  // simultaneously.
  if (!isNaN(props.accountId)) {
    try {
      await adminAccountsStore.fetchAccount(props.accountId)
    } catch {
      // ignore
    }

    attestationLoading.value = true
    try {
      const attestations = await assetService.listAttestations(props.accountId)
      attestation.value = attestations[0]
    } catch {
      // ignore
    } finally {
      attestationLoading.value = false
    }
  }
})

const uploadLogo = async (event: Event) => {
  const fileInput = event.target! as HTMLInputElement
  const file = fileInput.files?.[0]
  if (!file) {
    return
  }

  await adminAccountsStore.uploadLogo(account.value.id, file)
}

const deleteAccount = async () => {
  if (confirm(`Delete account '${account.value.name}'?`)) {
    await adminAccountsStore.deleteAccount(account.value.id)
    router.push({ name: "wc-admin-accounts" })
  }
}

const deleteAccountAssets = async () => {
  if (confirm(`Delete all assets for '${account.value.name}'?`)) {
    await adminAccountsStore.deleteAccountAssets(account.value.id)
    location.reload()
  }
}
</script>

<style scoped lang="scss">
table th {
  @apply text-left font-normal pr-8;
}

.tab {
  @apply px-4 py-2 rounded-md;
}

.router-link-active {
  @apply bg-neutral-30;
}
</style>
