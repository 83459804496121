import axios from "axios"
import { AxiosResponse } from "axios"
import { parsePaginationInfo } from "@/utils/parseHeaderPagination"

export type PageInfo = {
  nextPageUrl: string | undefined
  prevPageUrl: string | undefined
}
export type Paginated<T> = {
  data: T
  pageInfo: PageInfo
}

export default class BaseFetcher {
  domain: string
  constructor(domain: string) {
    this.domain = domain
  }

  httpGetRaw = async <T>(endpoint: string, params: any): Promise<AxiosResponse<T>> => {
    const url = `${this.domain}${endpoint}${this.getParams(params)}`
    const options = this.getHeaders()

    try {
      return await axios.get(url, options)
    } catch (error: any) {
      const message = this.getError(error)
      throw new Error(message)
    }
  }

  httpGet = async <T>(endpoint: string, params: any): Promise<T> => {
    const response = await this.httpGetRaw<T>(endpoint, params)
    return response.data
  }

  httpGetPaginated = async <T>(endpoint: string, params: any): Promise<Paginated<T>> => {
    if (endpoint.indexOf("?") !== -1) {
      // if this is a pre-formatted URL from pagination links, we don't need to
      // add params ourselves
      params = {}
    }

    const response = await this.httpGetRaw<T>(endpoint, params)
    return {
      data: response.data,
      pageInfo: parsePaginationInfo(response),
    }
  }

  httpGetFile = async <Type>(endpoint: string, params: any): Promise<Type> => {
    const url = `${this.domain}${endpoint}${this.getParams(params)}`
    const options = this.getHeaders()
    options.responseType = "blob"
    try {
      const response = await axios.get(url, options)
      return new Promise((resolve) => {
        resolve(response.data)
      })
    } catch (error: any) {
      const message = this.getError(error)
      return new Promise((resolve, reject) => {
        reject(new Error(message))
      })
    }
  }

  httpPostRaw = async (endpoint: string, data: any): Promise<AxiosResponse> => {
    const url = `${this.domain}${endpoint}`
    const options = this.getHeaders()
    return await axios.post(url, data, options)
  }

  httpPost = async <Type>(endpoint: string, data: any): Promise<Type> => {
    try {
      const response = await this.httpPostRaw(endpoint, data)
      return response.data
    } catch (error) {
      const message = this.getError(error)
      return new Promise((resolve, reject) => {
        reject(new Error(message))
      })
    }
  }

  httpPutRaw = async (endpoint: string, data: any): Promise<AxiosResponse> => {
    const url = `${this.domain}${endpoint}`
    const options = this.getHeaders()
    return await axios.put(url, data, options)
  }

  httpPut = async <Type>(endpoint: string, data: any): Promise<Type> => {
    try {
      const response = await this.httpPutRaw(endpoint, data)
      return response.data
    } catch (error: any) {
      throw new Error(this.getError(error))
    }
  }

  httpPatch = async <Type>(endpoint: string, data: any): Promise<Type> => {
    const url = `${this.domain}${endpoint}`
    const options = this.getHeaders()
    try {
      const response = await axios.patch(url, data, options)
      return response.data
    } catch (error: any) {
      throw new Error(this.getError(error))
    }
  }

  httpDelete = async <Type>(endpoint: string, data: any = null): Promise<Type> => {
    const url = `${this.domain}${endpoint}${this.getParams([])}`
    const options = this.getHeaders()
    if (data) {
      options.data = data
    }
    try {
      const response = await axios.delete(url, options)
      return response.data
    } catch (error: any) {
      throw new Error(this.getError(error))
    }
  }

  httpDownload = async (endpoint: string, data: any, filename: string) => {
    const response = (await this.httpGet(endpoint, data)) as BlobPart
    const blob = new Blob([response], { type: "text/csv" })
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = filename
    link.click()
    URL.revokeObjectURL(link.href)
  }

  httpUpload = async <Type>(endpoint: string, data: Record<string, any>, file: any): Promise<Type> => {
    const url = `${this.domain}${endpoint}`
    const options = this.getHeaders()
    options.headers["Content-Type"] = "multipart/form-data"

    const formData = new FormData()
    formData.append("file", file)
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value)
    }

    const response = await axios.post(url, formData, options)
    if (response.status >= 200 && response.status < 300) {
      return response.data
    }
    throw new Error(response.data.detail)
  }

  httpUploadPut = async <Type>(endpoint: string, data: Record<string, any>, file: any): Promise<Type> => {
    const url = `${this.domain}${endpoint}`
    const options = this.getHeaders()
    options.headers["Content-Type"] = "multipart/form-data"

    const formData = new FormData()
    formData.append("file", file)
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value)
    }

    const response = await axios.put(url, formData, options)
    if (response.status >= 200 && response.status < 300) {
      return response.data
    }
    throw new Error(response.data.detail)
  }

  getError(error: any): string {
    if (error?.response?.data?.detail) {
      const detail = error.response.data.detail
      if (Array.isArray(detail)) {
        return detail.map((err: any) => err.msg).join(" and ")
      } else {
        return detail
      }
    }
    if (error?.message) {
      return error.message
    }
    console.error(error)
    return "We encountered an unknown error."
  }

  getHeaders = () => {
    // This is an interface. It is overridden by the api-fetcher
    return {} as any
  }

  getParams = (params: any) => {
    const keyValues = []
    // if (!params["key"]) {
    //   params.key = this.key;
    // }

    for (const [key, value] of Object.entries(params)) {
      if (Array.isArray(value)) {
        value.forEach((v: any) => {
          keyValues.push(`${key}=${encodeURIComponent(v)}`)
        })
      } else {
        keyValues.push(`${key}=${encodeURIComponent(value as any)}`)
      }
    }

    return keyValues.length == 0 ? "" : "?" + keyValues.join("&")
  }
}
