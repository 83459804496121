<template>
  <WcModal :is-open="isOpen" :header="options.title || ''" :icon="options.icon" type="disrupt" @update:is-open="(value) => !value && handleCancel()">
    <template #panel>
      <span class="text-body-2 text-black">
        {{ options.message }}
      </span>
    </template>
    <template #footer>
      <WcButton :text="options.cancelText" variant="tertiary" size="small" @click="handleCancel" />
      <WcButton :text="options.confirmText" size="small" color="alert" @click="handleConfirm" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { WcButton } from "@/components/button"
import WcModal from "@/components/WcModal.vue"
import type { ConfirmOptions } from "./ConfirmContext"

defineProps<{
  isOpen: boolean
  options: ConfirmOptions
}>()

const emit = defineEmits<{
  confirm: []
  cancel: []
}>()

const handleConfirm = () => emit("confirm")
const handleCancel = () => emit("cancel")
</script>
