<template>
  <WcMultiselect
    v-if="options.length > 0"
    v-model="model"
    :name="name"
    :options="options"
    header="Supplier"
    all-selected-label="All suppliers"
    scroll-height="400px">
  </WcMultiselect>
</template>

<script setup lang="ts">
import { defineModel, ref, onMounted } from "vue"
import type { SelectOption } from "@/components/input"
import { WcMultiselect } from "@/components/input"
import { useRegistryService } from "@/services/service-container"

const registryService = useRegistryService()

const model = defineModel<number[] | null>()
defineProps<{ name: string }>()

const options = ref<SelectOption<number>[]>([])

onMounted(async () => {
  try {
    const suppliers = await registryService.getAccountSuppliers()
    options.value = suppliers.map((supplier) => ({
      label: supplier.displayName,
      value: supplier.accountId,
    }))
  } catch (error) {
    console.error("Error loading suppliers", error)
  }
})
</script>
