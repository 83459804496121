<template>
  <Dialog ref="dialog" class="p-dialog-maximized add-asset-group-with-assets-modal" modal :visible="isOpen" :closable="false" :draggable="false">
    <template #header>
      <div class="flex w-full flex-wrap items-center gap-3 text-white sm:justify-between">
        <div class="lg:basis-[228px]">
          <WcButtonIcon class="!top-0 size-7 cursor-pointer" icon="wc-carbon:arrow-left" color="green" variant="tertiary" @click="closeModal" />
        </div>
        <h1 class="text-body-1">Create Asset Group</h1>
        <div class="flex gap-3">
          <WcButton text="Cancel" size="medium" variant="secondary" dark-mode @click="closeModal" />
          <WcButton text="Create Group" size="medium" variant="primary" dark-mode @click="createAssetGroup" />
        </div>
      </div>
    </template>
    <h2 class="text-subheading-large-bold mb-16">New asset group</h2>
    <div class="sm:w-[424px]">
      <WcInputText v-model="assetGroupName" name="assetGroupName" inset-label="Asset Group Name" />
    </div>
    <div class="mt-12 w-full border-t border-divider-extralight pt-8">
      <div class="mt-4 max-h-[calc(100vh-28rem)] overflow-x-scroll pb-3">
        <AssetsTable :assets="assetsToAdd" show-eacs show-kind table-class="w-full" />
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useToast } from "vue-toastification"
import Dialog from "primevue/dialog"
import { WcButton } from "@/components/button"
import WcButtonIcon from "@/components/button/WcButtonIcon.vue"
import { WcInputText } from "@/components/input"
import type { AssetWithSummary } from "@/modules/asset/asset.service"
import { useAssetService } from "@/services/service-container"
import AssetsTable from "./AssetsTable.vue"

const assetService = useAssetService()
const toast = useToast()

const emit = defineEmits(["asset-group-added"])

const isOpen = ref<boolean>(false)
const dialog = ref()
const assetsToAdd = ref<AssetWithSummary[]>([])
const assetGroupName = ref<string>("")

const handleUpdateIsOpen = (newValue: boolean) => {
  isOpen.value = newValue
  if (!newValue) {
    resetModal()
  }
}

const resetModal = () => {
  assetGroupName.value = ""
}

const createAssetGroup = async () => {
  const assetIdsToAdd = assetsToAdd.value.map((asset) => asset.id)
  try {
    const assetGroup = await assetService.createAssetGroup({ name: assetGroupName.value })
    await assetService.addAssetsToAssetGroup(assetGroup.id, assetIdsToAdd)
    emit("asset-group-added", assetGroup)
    toast.success(`Asset group ${assetGroup.name} created with ${assetIdsToAdd.length} assets`)
    return assetGroup
  } catch (error) {
    toast.error("Failed to create asset group. Please try again.")
    throw error
  }
}

const openModal = (assets: AssetWithSummary[]) => {
  assetsToAdd.value = assets
  handleUpdateIsOpen(true)
}
const closeModal = () => handleUpdateIsOpen(false)
defineExpose({ openModal, closeModal })
</script>

<style lang="scss">
.add-asset-group-with-assets-modal {
  .p-dialog-header {
    @apply px-4 md:px-[60px] py-3 bg-black;
  }
  .p-dialog-content {
    @apply px-4 md:px-[60px] py-12;
  }
  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    border-radius: 0;
  }
  .p-dialog-header-icons {
    display: none;
  }
}
</style>
